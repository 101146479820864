/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useMutation } from '@apollo/client';
import { handleApolloError } from '@exo/frontend-common-apollo';

export const SAVE_SEARCH = gql`
  mutation SaveSearch($favouriteSearchName: String!, $functionId: ID!, $businessIds: [ID], $insurerIds: [ID]) {
    saveFavouriteSearch(favouriteSearchName: $favouriteSearchName, functionId: $functionId, businessIds: $businessIds, insurerIds: $insurerIds) 
  }
`;

export const useSaveSearch = () => {
    const [saveSearch, { data, loading, error }] = useMutation(SAVE_SEARCH, {
        onError(err) {
            if (process.env.NODE_ENV === 'development' || process.env.JEST_WORKER_ID !== undefined) {
                // eslint-disable-next-line no-console
                console.error(`Error ${__filename}: ${JSON.stringify(err, undefined, '  ')}`);
            }
            return err;
        }
    });

    handleApolloError(__filename, error);
    return {
        saveSearch: async (favouriteSearchName, functionId, businessIds, insurerIds) => {
            return saveSearch({
                variables: { favouriteSearchName, functionId, businessIds, insurerIds }
            });
        },
        data,
        loading,
        error
    };
};


