/* eslint-disable no-restricted-syntax */
/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React from 'react';
import * as S from './Footer.styles';
import parse from 'html-react-parser';
import { useIntl } from '@exo/frontend-common-i18n';

export const Footer = ({ links, intlPrefix }: Props) => {
  const intl = useIntl(intlPrefix);
  const contentMap = {
    copyright: new Date().getFullYear(),
    termsandco: `<a href=${links?.sectionsRight![0].items[0].url} target="_blank"> ${intl.msg(
      'termsandco.label',
      'Terms & Conditions'
    )}</a>`
  };
  return (
    <S.Footer>
      {links && (
        <S.Content>
          <S.Columns>
            <S.Section1>
              {links.sectionsLeft?.map(section => (
                <S.Column key={`footer-${section.title}`}>
                  <S.Items key={`footer-ul-${section.title}`}>
                    {section?.items.map(link => (
                      <S.Item key={`footer-item-${section.title}-${link.title}`}>
                        {parse(
                          intl.msg(link.title as string, '', {
                            data: contentMap[link.title as string]
                          }) as string
                        )}
                      </S.Item>
                    ))}
                  </S.Items>
                </S.Column>
              ))}
            </S.Section1>
            <S.Section2>
              {links.sectionsRight?.map(section => (
                <S.Column key={`footer-${section.title}`}>
                  <S.Items key={`footer-ul-${section.title}`}>
                    {section?.items.map(link => {
                      return (
                        <S.Item key={`footer-item-${section.title}-${link.url}`}>
                          <S.Link>
                            <a href={link.url} target="_blank">
                              {link.title}
                            </a>
                          </S.Link>
                        </S.Item>
                      );
                    })}
                  </S.Items>
                </S.Column>
              ))}
            </S.Section2>
          </S.Columns>
        </S.Content>
      )}
    </S.Footer>
  );
};

type Props = {
  links: {
    sectionsLeft?: {
      title: string | React.ReactNode;
      items: {
        title: string | React.ReactNode;
      }[];
    }[];
    sectionsRight?: {
      title: string | React.ReactNode;
      items: {
        url: string;
        title: string | React.ReactNode;
      }[];
    }[];
  };
  intlPrefix: string;
};
