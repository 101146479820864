/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/


import { gql, useQuery } from '@apollo/client';
import { handleApolloError, State } from '@exo/frontend-common-apollo';

const transformResponse = <T>(data: any): T => {
    return data?.favouriteServices;
};

export const GET_FAVOURITE_SERVICES = gql`   
query GetFavouriteServices {
    favouriteServices {
        serviceId
        serviceLevel
        serviceType
        favouriteServiceId
        favouriteServiceName
        templateId
        ibls {
            iblId
            iblName
            iblReference
        }
        hasAccess
    }
}`

export const useFavouriteServices = <T>(): Result<T> => {
    const { loading, data, error, refetch } = useQuery(GET_FAVOURITE_SERVICES)

    handleApolloError(__filename, error);

    return { loading, data: transformResponse(data), error, refetch };
};

type Result<T> = {
    data?: T;
    refetch: () => void;
} & State;
