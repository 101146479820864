/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

export const isInternalError = (error): boolean => {
  return error?.networkError?.statusCode === 500;
};

export const isForbidden = (error): boolean => {
  return (
    error?.networkError?.statusCode === 403 ||
    error?.graphQLErrors?.[0]?.extensions?.classification === 'AuthorizationException' ||
    error?.response?.status === 403
  );
};

export const isUnauthorized = (error): boolean => {
  return (
    error?.networkError?.statusCode === 401 ||
    error?.graphQLErrors?.[0]?.extensions?.classification === 'AccessDeniedException' ||
    error?.response?.status === 401
  );
};

export const isDataFetchingException = (error): boolean => {
  return error?.graphQLErrors?.[0]?.extensions?.classification === 'DataFetchingException';
};

export const isGatewayTimeOut = (error): boolean => {
  return error?.networkError?.statusCode === 504 || error?.response?.status === 504;
};
