/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React from 'react';
import { Level1Item, Menu } from '../Menu/Menu';
import * as S from './LeftMenu.styles';
import { useLocation } from 'react-router-dom';

export const LeftMenu = ({ children, data }: Props) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <S.DashboardPageContainer>
      <S.DashboardPageMenu>
        <Menu items={data?.items} activeUrl={pathname}></Menu>
      </S.DashboardPageMenu>
      <S.DashboardPageContent>{children}</S.DashboardPageContent>
    </S.DashboardPageContainer>
  );
};

type Root = {
  title?: string | undefined;
  items: Level1Item[];
};

export type ChildItem = {
  label: string;
  url: string;
  items?: ChildItem[];
  id?: string;
};

type Props = {
  children: any;
  data: Root;
};
