/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

/* eslint-disable no-underscore-dangle */

import styled, { css } from 'styled-components';
import { media, responsiveFontBlock } from '@exo/frontend-common-style-utils';
import theme from './Header.theme';

export const HeaderRow = styled.div`
  background: ${props => theme(props).background};
  color: ${props => theme(props).color};
  display: grid;
  grid-template-areas: 'menu-toggle logo divider secondary NONE search tertiary actions';
  grid-template-columns: auto auto 0.063rem auto 1fr auto auto auto;
  grid-template-rows: 1fr;
  padding: ${props => theme(props).padding};

  ${props =>
    media.greaterThan(props, 'small').then(css`
      padding: ${theme(props).$$large.padding};
    `)}

  ${props =>
    media.lessThan(props, 'large').then(css`
      height: 2rem;
      font-size: 0.625rem;
    `)}

  .action {
    border: none;
    color: ${props => theme(props).color};
    cursor: pointer;

    /* stylelint-disable-next-line */
    [role='img'] {
      align-items: center;
      display: flex;

      /* stylelint-disable-next-line */
      svg {
        color: ${props => theme(props).color};
        height: 100%;
      }
    }
  }
`;

export const Navigation = styled.div`
  align-items: center;
  display: flex;
  grid-area: secondary;
  margin-left: 2rem;
  ${props => responsiveFontBlock(theme(props).font)};

  .action {
    border-bottom: 0.25rem solid ${props => theme(props).background};
    padding: 1.2rem 1.375rem 0.9rem 1.375rem;
    font-size: 0.75rem;
  }

  .active {
    border-bottom: 0.25rem solid #fff;
    font-size: 0.75rem;
    padding: 1.25rem 1.375rem 0.85rem 1.375rem;
  }

  /* stylelint-disable-next-line selector-max-type */
  a {
    color: ${props => theme(props).color};
  }

  /* stylelint-disable-next-line */
  [role='img'] {
    display: none;
  }
  ${props =>
    media.lessThan(props, 'large').then(css`
      font-size: 0.625rem;
      height: 2rem;

      .action {
        border-bottom: 0.2rem solid ${() => theme(props).background};
        font-size: 0.625rem;
        padding: 0.6rem 1rem 0.4rem 1rem;
      }

      .active {
        border-bottom: 0.2rem solid #fff;
        font-size: 0.625rem;
        padding: 0.6rem 1rem 0.4rem 1rem;
      }
    `)}
`;

export const MenuToggle = styled.div`
  align-items: center;
  display: flex;
  grid-area: menu-toggle;
  margin-right: 0rem;

  .action {
    padding: ${props => theme(props).__action.padding};
  }

  ${props =>
    media.greaterThan(props, 'large').then(css`
      display: none;
    `)}
`;

export const Logo = styled.div`
  align-items: center;
  display: flex;
  grid-area: logo;
  ${props =>
    media.lessThan(props, 'large').then(css`
      height: 1.5rem;
      margin-top: 0.2rem;
    `)}

  /* stylelint-disable-next-line */
  img,
  svg {
    width: ${props => theme(props).__logo.maxWidth};
    height: ${props => theme(props).__logo.maxHeight};
    max-width: ${props => theme(props).__logo.maxWidth};
    max-height: ${props => theme(props).__logo.maxHeight};

    ${props =>
      media.greaterThan(props, 'large').then(css`
        height: ${theme(props).__logo.$$large.maxHeight};
        max-height: ${theme(props).__logo.$$large.maxHeight};
        max-width: ${theme(props).__logo.$$large.maxWidth};
        width: ${theme(props).__logo.$$large.maxWidth};
      `)}
  }
`;

export const Divider = styled.div`
  border-left: 0.063rem solid ${props => theme(props).borderColor};
  display: flex;
  grid-area: divider;
  height: 2rem;
  margin-left: 1rem;
  margin-top: 0.7rem;
  ${props =>
    media.lessThan(props, 'large').then(css`
      height: 0.875rem;
      margin-top: 0.625rem;
    `)}
`;

export const Actions = styled.div`
  align-items: center;
  display: flex;
  grid-area: actions;

  .action {
    padding: ${props => theme(props).__action.padding};
  }
`;

export const Tertiary = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.75rem;
  grid-area: tertiary;
  margin-left: 2rem;

  ${props => responsiveFontBlock(theme(props).font)};
  /* stylelint-disable-next-line selector-max-type */
  a {
    color: ${props => theme(props).color};
  }

  .action {
    border-bottom: 0.25rem solid ${props => theme(props).background};
    font-size: 0.75rem;
    padding: 1.2rem 1.375rem 0.9rem 1.375rem;

    /* stylelint-disable-next-line */
    [role='img'] {
      display: none;
    }
  }

  .active {
    border-bottom: 0.25rem solid #fff;
    font-size: 0.75rem;
    padding: 1.25rem 1.375rem 0.9rem 1.375rem;
  } 

  ${props =>
    media.lessThan(props, 'large').then(css`
      font-size: 0.625rem;
      height: 2rem;

      .action {
        border-bottom: 0.2rem solid ${() => theme(props).background};
        font-size: 0.625rem;
        padding:  0.6rem 1rem 0.4rem 1rem;
      }

      .active {
        border-bottom: 0.2rem solid #fff;
        font-size: 0.625rem;
        padding:  0.6rem 1rem 0.4rem 1rem;
      }
    `)}

  .welcome-user {
    color: ${props => theme(props).welcomeUserColor};
    margin-right: 1rem;
    max-width: 18.75rem;
    ${props =>
      media.lessThan(props, 'large').then(css`
        max-width: 7.5rem;
      `)}
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Search = styled.div`
  align-items: center;
  display: flex;
  grid-area: search;
  justify-content: flex-end;

  .action {
    padding: ${props => theme(props).__action.padding};
  }
`;
