/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import TrashCan from '@carbon/icons/lib/trash-can/16';
import ArrowLeft from '@carbon/icons/lib/arrow--left/16';
import ArrowRight from '@carbon/icons/lib/arrow--right/16';
import Search from '@carbon/icons/lib/search/16';
import { getAttributes, toString } from '@carbon/icon-helpers';
import { ProductsServicesEvents } from '../../productsServicesEvents';

export const renderIcon = (icon, label = '', cssClass = 'svgContainer') => {
  const svg = toString({
    ...icon,
    attrs: getAttributes(icon.attrs)
  });
  const element = document.createElement('span');
  element.className = cssClass;
  element.innerHTML = `${label} ${svg}`;
  return element;
};

const removeTitle = doc => {
  const title: HTMLElement | null = doc.querySelector('.hdlevel1');
  if (title) title!.style.display = 'none';
};

// in repeat control, we remove previous/next keywords
const parseRepeatControl = doc => {
  const repeatControllsFDH = doc.querySelectorAll('.repeatControl .FDH');
  for (const fdh of repeatControllsFDH) {
    let inner = fdh.innerHTML.trim();
    if (inner.includes('next') || inner.includes('previous')) {
      if (inner.split(' ').length > 1) {
        fdh.textContent = inner.replace('next', '').replace('previous', '').trim();
      }
    }
  }
};

export const retrySubmitXForm = items => {
  window.clickLink('retry-submit', items);
};

export const openLongList = item => {
  const id = item.getAttribute('id').split('_SEARCH')[0];
  const parent = item?.parentElement;
  const inputs = parent.querySelectorAll(`input[id^=${id}_K]`);
  const keys = [] as any;
  if (inputs?.length == 0) {
    const sDescription = {
      name: parent?.querySelector(`#${id}_LONGLIST_CAPTION`).value,
      columnName: 'Short Description',
      mandatory: false,
      caption: parent?.querySelector(`#${id}_LONGLIST_CAPTION`).value,
      fieldName: 'short_description'
    };
    keys.push(sDescription);
  } else {
    for (const input of inputs) {
      if (input.id.includes('KEYFIELD')) continue;
      keys.push({
        name: parent?.querySelector(`#${input.id}_KEYFIELD_NAME`).value,
        columnName: parent?.querySelector(`#${input.id}_KEYFIELD_COLUMNNAME`).value,
        mandatory: parent?.querySelector(`#${input.id}_KEYFIELD_MANDATORY`).value === 'Y', // enums doesn't accept String = boolean
        caption: parent?.querySelector(`#${input.id}_KEYFIELD_CAPTION`).value,
        fieldName: parent
          ?.querySelector(`#${input.id}_KEYFIELD_COLUMNNAME`)
          .value.toLowerCase()
          .trim()
          .replace(' ', '_')
      });
    }
  }
  const obj = {
    id: id,
    listOwner: parent?.querySelector(`#${id}_LONGLIST_LISTOWNER`).value,
    listNo: parent?.querySelector(`#${id}_LONGLIST_LISTNO`).value,
    showExpired: parent?.querySelector(`#${id}_LONGLIST_SHOWEXPIRED`).value,
    shortDescIsKey: parent?.querySelector(`#${id}_LONGLIST_SHORTDESCISKEY`).value,
    caption: parent?.querySelector(`#${id}_LONGLIST_CAPTION`).value ?? '',
    displayField: parent?.querySelector(`#${id}_LONGLIST_DISPLAY_FIELD`).value,
    keys: keys
  };
  document.dispatchEvent(
    new CustomEvent(ProductsServicesEvents.OpenLongList, {
      detail: obj
    })
  );
};

const parseSearchButton = doc => {
  const searchButtons = doc.querySelectorAll('a[id$="_SEARCH"]');
  for (const searchButton of searchButtons) {
    const id = searchButton.getAttribute('id');
    searchButton.removeAttribute('href');
    searchButton.setAttribute('onclick', `openLongList(${id})`);
  }
};

const parseAttachmentLink = doc => {
  const closeButton = doc.querySelector('#file_attachments a');
  closeButton?.removeAttribute('href');
};

const replaceGif = (doc, gifSelector, icon, text = '', cssClass = '') => {
  const elements = doc.querySelectorAll(`img[src$="${gifSelector}"]`);
  for (const element of elements) {
    const parent = element.parentElement;
    element.remove();
    if (icon) {
      parent.appendChild(renderIcon(icon, text, cssClass));
      continue;
    }
    parent.innerText = text;
  }
};

export const parseXForm = (string, isRsp = false) => {
  string = string.replaceAll('textareax', 'bx--text-area');
  const doc = new DOMParser().parseFromString(string, 'text/html');
  const repeatContainers = doc.querySelectorAll('.repeatContainer');
  const rootGroup: HTMLElement = doc.querySelector('#root') as HTMLElement;

  // @ts-ignore
  const attachments: HTMLElement = rootGroup?.querySelector('.attachments') as HTMLElement;
  rootGroup?.classList.add('template-root');
  // @ts-ignore
  const toAppend = [] as any;

  const gifsToBeReplaced = [
    {
      selector: 'gen_arrow_style3_left.gif',
      icon: ArrowLeft,
      text: '',
      cssClass: 'left-arrow'
    },
    {
      selector: 'gen_arrow_style3.gif',
      icon: ArrowRight,
      text: '',
      cssClass: 'right-arrow'
    },
    {
      selector: 'icon_delete.gif',
      icon: TrashCan,
      text: 'Delete',
      cssClass: 'delete-icon'
    },
    {
      selector: 'butt_search.gif',
      icon: Search,
      text: '',
      cssClass: 'search-icon'
    },
    {
      selector: 'butt_submit.gif',
      icon: null,
      text: 'Submit'
    }
  ];

  if (isRsp) {
    // disable submit
    const submitButton = doc.getElementById('submitDiv') as HTMLElement;
    const table = doc.getElementsByTagName('table')[0] as HTMLElement;
    table.classList.add('response-table');
    submitButton.style.display = 'none';
    // all inputs must be read-only
    // const form = doc.getElementsByTagName('form')[0];
    // const formElements = Array.from(form.elements);
    // const links = Array.from(doc.getElementsByTagName('a'));
    // for (const element of formElements) {
    //   //@ts-ignore
    //   element.readOnly = true;
    // }
    // for (const link of links) {
    //   link.removeAttribute('href');
    //   link.removeAttribute('onclick'); // this is not working for some reasons. to verify again in the future
    // }

    // retry button
    // moved in TemplatePage.tsx
  }

  for (const repeatContainer of repeatContainers) {
    const rC: HTMLElement = repeatContainer as HTMLElement;
    if (rC.style.getPropertyValue('background-color') == 'rgb(193, 214, 255)') {
      rC.setAttribute('style', '');
      rC.classList.add('polarisRepeatContainer');
    }

    if (rC.style.getPropertyValue('background-color') == 'rgb(224, 234, 255)') {
      rC.setAttribute('style', '');
      rC.classList.add('polarisRepeatContainer2');
    }

    if (rC.style.getPropertyValue('background-color') == 'rgb(163, 194, 255)') {
      rC.setAttribute('style', '');
      rC.classList.add('polarisRepeatContainer3');
    }
  }

  removeTitle(doc);
  parseRepeatControl(doc);
  parseSearchButton(doc);
  parseAttachmentLink(doc);
  for (const gif of gifsToBeReplaced) {
    replaceGif(doc, gif.selector, gif.icon, gif.text, gif.cssClass);
  }

  string = doc.body.innerHTML;
  return string;
};

export const extractTitle = string => {
  const doc = new DOMParser().parseFromString(string, 'text/html');
  const rootGroup: HTMLElement = doc.getElementById('root') as HTMLElement;
  const formTitle: HTMLElement = rootGroup?.querySelector('.hdlevel1') as HTMLElement;
  return formTitle?.innerHTML.replace('&nbsp;', '') ?? '';
};

export const addTemplateInfo = (string, inputName, info) => {
  const doc = new DOMParser().parseFromString(string, 'text/html');
  const hiddenInput = doc.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', inputName);
  hiddenInput.setAttribute('value', info);
  doc.body.appendChild(hiddenInput);
  return doc.body.innerHTML;
};
