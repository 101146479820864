/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { renderDefaultError, SmartComponentProps } from '@exo/frontend-common-utils';
import { renderPolarisLoading } from '@exo/frontend-features-insurance-chrome-logic';
import { useEffect, useState } from 'react';
import { useGetServices } from '../../hooks/useGetServices';
import { useSaveSearch } from '../../hooks/useSaveFavouriteSearch';
import { useSaveService } from '../../hooks/useSaveFavouriteService';
import { useInvokeClickThru } from '../../hooks/useInvokeClickThru';
import {
  GetServicesResultOutput,
  ServiceInput,
  SuccessType,
  TemplateConfig
} from '../../model/types';

const ErrorFilter = error => {
  if (!error) return false;
  if (
    error?.graphQLErrors?.[0]?.extensions.classification === 'DataFetchingException' ||
    error?.graphQLErrors?.[0]?.extensions?.message ===
      'You no longer have access to this service. This service may no longer exist or access to it have been changed. If this service is no longer relevant, we suggest that you delete this favourite from your list. To check this service or find other products or services try using the menu options, other items in your favourites list or the quick search facility.'
  ) {
    return false;
  }
  return true;
};

export const ServicesDisplayContainer = ({
  render,
  renderError = renderDefaultError,
  config,
  onSuccess,
  onSearchFail,
  onServiceFail,
  functionId,
  insurersIds,
  businessAreaId,
  keywords
}: Props) => {
  const { saveService, error: submitError, loading: saveServiceLoading } = useSaveService();
  const { saveSearch, error: saveSearchError, loading: saveSearchLoading } = useSaveSearch();
  const { loading, data, error } = useGetServices<GetServicesResultOutput>({
    functionId,
    insurersIds,
    businessAreaId,
    keywords
  });
  const {
    loading: invokeClickThruLoading,
    error: invokeClickThruError,
    onInvoke
  } = useInvokeClickThru(config);

  const [success, setSuccess] = useState<SuccessType>();

  useEffect(() => {
    if (success?.service || success?.search) {
      document.dispatchEvent(
        new CustomEvent('favs_update', {
          detail: success
        })
      );
    }
  }, [success?.timestamp, success?.timestamp]);

  const commonLoading =
    loading || saveSearchLoading || saveServiceLoading || invokeClickThruLoading;
  useEffect(() => {
    return renderPolarisLoading(commonLoading);
  }, [commonLoading]);

  const commonError = error || invokeClickThruError;
  if (commonError || ErrorFilter(submitError) || ErrorFilter(saveSearchError))
    return renderError(commonError || submitError || saveSearchError);

  const onSaveService = (input: ServiceInput) => {
    saveService(input.serviceId, input.favouriteServiceName, input.serviceLevel).then(rsp => {
      if (rsp?.data?.saveFavouriteService) {
        // in case you save a service multiple times with same name, useEffect won't trigger.
        // using timestamp instead of name to trigger useEffect
        setSuccess({ service: input.favouriteServiceName, timestamp: Date.now().toString() });
        onSuccess();
      }
      if (rsp?.errors) {
        onServiceFail(rsp?.errors);
      }
    });
  };

  const onSaveSearch = input => {
    saveSearch(input.name, input.functionId, input.businessIds, input.insurerIds).then(rsp => {
      if (rsp?.data?.saveFavouriteSearch) {
        setSuccess({ search: input.name, timestamp: Date.now().toString() });
        onSuccess();
      }
      if (rsp?.errors) {
        onSearchFail(rsp?.errors);
      }
    });
  };

  const invokeFunc = (input: any) => {
    return onInvoke(input);
  };

  return render({
    onSaveService,
    onSaveSearch,
    invokeFunc,
    items: data!,
    loading
  });
};

type QuickSearchContainerRenderProps = {
  onSaveService: (data: ServiceInput) => void;
  onSaveSearch: (data: any) => void;
  invokeFunc: (input: any) => void;
  errors?: any;
  items: GetServicesResultOutput;
  loading?: boolean;
};

type Props = SmartComponentProps<{
  render: (props: QuickSearchContainerRenderProps) => JSX.Element;
  onSuccess: () => void;
  onSearchFail: (error: any) => void;
  onServiceFail: (error: any) => void;
  config: TemplateConfig;
  keywords?: string;
  functionId?: string;
  insurersIds?: string[];
  businessAreaId?: string;
}>;
