/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { renderDefaultError, SmartComponentProps } from '@exo/frontend-common-utils';
import { renderPolarisLoading } from '@exo/frontend-features-insurance-chrome-logic';
import { useEffect } from 'react';
import { useInvokeInformation } from '../../hooks/useInvokeInformation';
import { TemplateConfig } from '../../model/types';

export const InformationServiceContainer = ({
  render,
  renderError = renderDefaultError,
  config,
  services
}: Props) => {
  const { data, loading, error, callInformation } = useInvokeInformation(config);

  useEffect(() => {
    if (services) {
      callInformation(services);
    }
  }, [services]);

  useEffect(() => {
    return renderPolarisLoading(loading);
  }, [loading]);

  if (error) {
    return renderError(error);
  }

  return render({
    data,
    loading
  });
};

type InformationServiceContainerRenderProps = {
  errors?: any;
  loading?: boolean;
  data?: string;
};

type Props = SmartComponentProps<{
  render: (props: InformationServiceContainerRenderProps) => JSX.Element;
  config: TemplateConfig;
  services?: string;
}>;
