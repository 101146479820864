/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import menuItems from './data/menuItems.json';
import { useLocation } from 'react-router-dom';
import { usePolarisSessionContext } from '@exo/frontend-common-polaris-session-context';
import { useFunctionTreeByUserCode } from '@exo/frontend-common-insurance-mutation';
import { gql } from '@apollo/client';
import { renderDefaultError, SmartComponentProps } from '@exo/frontend-common-utils';
import { parseTree, Root } from './LeftMenuHelper';
import { useEffect } from 'react';
import { renderPolarisLoading } from '@exo/frontend-features-insurance-chrome-logic';

export const LeftMenu = ({ render, renderError = renderDefaultError, loadDynamicMenu }: Props) => {
  const { pathname } = useLocation();
  const menuKey = pathname.split('/')[1];
  const session = usePolarisSessionContext();
  const roles = session?.roles[0];
  let items;
  if (roles && roles !== '') items = menuItems[roles][menuKey];

  const { loading, data, error } = useFunctionTreeByUserCode<FunctionTreeResultOutput>(
    [LeftMenu.fragment, LeftMenu.childrenFragment],
    loadDynamicMenu
  );

  useEffect(() => {
    if (loading) {
      return renderPolarisLoading(loading);
    }
  }, [loading]);

  if (error) return renderError(error);
  //'ATU05.1';
  if (items?.items?.length === 0 && data) {
    /* c8 ignore next 6 */
    items = [];
    if (data?.functionTreeByUserCode?.functionTreeOutput?.length > 0) {
      const root = data?.functionTreeByUserCode?.functionTreeOutput[0];
      items = parseTree(root);
    }
  }

  if (!items) return <></>;

  return render({ items });
};

LeftMenu.childrenFragment = gql`
  fragment LeftMenuChildrenFragment on FunctionTreeNodeOutput {
    nodeId
    functionName
    tabPriority
    parentName
  }
`;

LeftMenu.fragment = gql`
  fragment LeftMenuFunctionTreeContainer on FunctionTreeNodeOutput {
    nodeId
    children {
      ...LeftMenuChildrenFragment
      children {
        ...LeftMenuChildrenFragment
      }
    }
    functionName
  }
`;

type FunctionTreeResultOutput = {
  functionTreeByUserCode: {
    functionTreeOutput: FunctionTreeNodeOutput[];
    levels: number;
  };
};

type FunctionTreeNodeOutput = {
  nodeId: number;
  children?: Array<FunctionTreeNodeOutput> | null;
  functionName: string;
  functionDescription: string;
  guiPriority?: number | string;
  isLeafNode?: boolean;
  tabPriority?: number;
  parentId: string;
  parentName: string;
};

type LeftMenuRenderProps = {
  items: Root;
};

type Props = SmartComponentProps<{
  render: (props: LeftMenuRenderProps) => JSX.Element;
  loadDynamicMenu: boolean;
}>;
