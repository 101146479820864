/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

// removes Typename from GQL result
export const removeTypename = result => {
  return JSON.parse(
    JSON.stringify(result, (key, value) => {
      if (key === '__typename') return;
      return value;
    })
  );
};
