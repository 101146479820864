/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useMutation } from '@apollo/client';
import { handleApolloError } from '@exo/frontend-common-apollo';

export const DELETE_SEARCH = gql`
  mutation DeleteSearch($favouriteSearchId: ID!) {
    deleteFavouriteSearch(favouriteSearchId: $favouriteSearchId ) 
  }
`;

export const useDeleteSearch = () => {
    const [deleteSearch, { data, loading, error }] = useMutation(DELETE_SEARCH, {
        onError(err) {
            if (process.env.NODE_ENV === 'development' || process.env.JEST_WORKER_ID !== undefined) {
                // eslint-disable-next-line no-console
                console.error(`Error ${__filename}: ${JSON.stringify(err, undefined, '  ')}`);
            }
            return err;
        }
    });

    handleApolloError(__filename, error);
    return {
        deleteSearch: async (favouriteSearchId) => {
            return deleteSearch({
                variables: { favouriteSearchId }
            });
        },
        data,
        loading,
        error
    };
};


