/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { DeepPartial, ResponsiveFont } from '@exo/frontend-theme-base-theme';
import * as CSS from 'csstype';
import { merge } from 'lodash';

type Props = {
  background: CSS.Property.Background;
  color: CSS.Property.Color;
  borderColor: CSS.Property.Color;
  padding: CSS.Property.Padding;
  font: ResponsiveFont;
  welcomeUserColor: CSS.Property.Color;
  $$large: {
    padding: CSS.Property.Padding;
    minHeight: CSS.Property.Height;
  };
  __action: {
    padding: CSS.Property.Padding;
  };
  __logo: {
    maxWidth: CSS.Property.MaxWidth;
    maxHeight: CSS.Property.MaxHeight;
    $$large: {
      maxWidth: CSS.Property.MaxWidth;
      maxHeight: CSS.Property.MaxHeight;
    };
  };
};

declare global {
  interface EXOComponentStyles {
    core_masthead_header?: DeepPartial<Props>;
  }
}

export default (props: EXOThemeProps): Props => {
  return merge(
    {
      // Default theme
      background: props.theme.colors.backgrounds.panels.primary.base,
      color: props.theme.colors.text.primary,
      borderColor: '#b6a5cc',
      padding: '0',
      font: props.theme.typography.body.short.S,
      welcomeUserColor: '#D0ACD0',
      $$large: {
        padding: '1rem',
        minHeight: '3.75rem',
        font: props.theme.typography.body.short.M
      },
      __action: {
        padding: '0.75rem'
      },
      __logo: {
        maxWidth: '3rem',
        maxHeight: '2rem',
        $$large: {
          maxWidth: '6rem',
          maxHeight: '4rem'
        }
      }
    },
    props.theme?.byComponent?.core_masthead_header
  );
};
