/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React from 'react';
import * as S from './Header.styles';

export const Header = (props: Props) => {
  return (
    <S.HeaderRow id="header">
      <S.MenuToggle>{props.menuToggle}</S.MenuToggle>
      <S.Logo>{props.logo}</S.Logo>
      <S.Divider></S.Divider>
      {props.navigation && <S.Navigation>{props.navigation}</S.Navigation>}
      <S.Search>{props.search}</S.Search>
      <S.Tertiary>{props.tertiary}</S.Tertiary>
      <S.Actions>{props.actions}</S.Actions>
    </S.HeaderRow>
  );
};

type Props = {
  menuToggle?: React.ReactElement;
  logo?: React.ReactElement;
  actions?: React.ReactElement;
  navigation?: React.ReactElement;
  search?: React.ReactElement;
  tertiary?: React.ReactElement;
};
