/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import * as CSS from 'csstype';
import { rgba } from 'color2k';

type Props = {
  loadingBgColor: CSS.Property.BackgroundColor;
  loadingBgColorTr: CSS.Property.BackgroundColor;
  textColor: CSS.Property.Color;
};

declare global {
  interface EXOComponentStyles {
    insurance_loadingTile?: Partial<Props>;
  }
}

export default (props: EXOThemeProps): Props => {
  return {
    loadingBgColorTr: rgba(244, 244, 244, 0),
    loadingBgColor: rgba(244, 244, 244, 0.6),
    textColor: props.theme.colors.text.purple,

    ...props.theme?.byComponent?.insurance_loadingTile
  };
};
