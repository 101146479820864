/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

export const sortByName = (a, b) => {
  a = a.name.toLowerCase();
  b = b.name.toLowerCase();
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const sortByField = field => {
  return function (a, b) {
    a = a[field].toLowerCase();
    b = b[field].toLowerCase();

    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };
};
