/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/


import {
    SmartComponentProps
} from '@exo/frontend-common-utils';
import { renderPolarisLoading } from '@exo/frontend-features-insurance-chrome-logic';
import { useEffect, useState } from 'react';

export const FormNavigatorContainer = ({ render,
    event
}: Props) => {

    const [formNavigator, setFormNavigator] = useState<any>(undefined)

    useEffect(() => {
        if (navlinks) {
            const navigator = [] as any
            for (const navlink of navlinks) {
                const obj = {} as any
                const linkId = navlink.anchorName.split('_')[0]
                if (navlink.anchorName.includes('@')) {
                    const id = navlink.anchorName.split('@')[1].split('_')[0] // id can be this form i81_B@i45_NAVLINK
                    const parentNavLink = navigator.find(item => item.id == id)
                    if (parentNavLink) {
                        const child = {
                            id: linkId,
                            label: navlink.labelName,
                            depth: navlink.levelDepth
                        }
                        parentNavLink.children.push(child)
                        continue
                    }
                }
                obj.id = linkId
                obj.label = navlink.labelName
                obj.children = []
                obj.depth = navlink.levelDepth
                navigator.push(obj)
            }
            setFormNavigator(navigator)
        }

    }, [event?.navlinks])

    useEffect(() => {
        // in case you exit from the form and enter again on the same one, links will be added to the existing navlinks. 
        // navlinks need to be refreshed
        return () => {
            //@ts-ignore
            navlinks = []
        }
    }, [])

    useEffect(() => {
        return renderPolarisLoading(!!formNavigator);
    }, [!!formNavigator]);

    return render({
        items: formNavigator
    });
}

type FormNavigatorItem = {
    label: string;
    id: string;
    depth: number;
}

type FormNavigatorContainerRenderProps = {
    items?: {
        label: string;
        id: string;
        depth: number;
        children?: FormNavigatorItem[];
    }[];
}

type Props = SmartComponentProps<{
    render: (props: FormNavigatorContainerRenderProps) => JSX.Element;
    templateId: string;
    event?: any;
}>;