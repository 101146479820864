/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/
import styled, { css } from 'styled-components';
import { media } from '@exo/frontend-common-style-utils';
import theme from './PageNotFound.theme';
import { Link as ReactLink } from '@exo/frontend-common-link';

export const PageNotFound = styled('div')`
  display: flex;
  justify-content: center;

  ${props => media.greaterThan(props, 'medium').then(css`
   padding: 4rem;
  `)}

  ${props => media.greaterThan(props, 'small').then(css`
    padding: 4rem;
  `)}
`;

export const Container = styled('div')`
  background-color: ${props => theme(props).containerColor};
  max-height: 7rem;
  padding-top: 2.06rem;
  width: 31.5rem;

  .last{
    padding-bottom: 1.75rem;
  }
`;

export const Text = styled('p')`
  font-size: 0.875rem;
  padding-bottom: 0.81rem;
  padding-left: 2.06rem;
`

export const Link = styled(ReactLink)`
  color: ${props => theme(props).colorPurple};
  text-decoration: underline;
`