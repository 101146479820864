/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React from 'react';
import { Dropdown as BaseDropdown } from '@exo/frontend-components-base';
import { useFieldPanelContext } from '../../layout/FieldPanel/FieldPanel';
import { ControlledFieldProps } from '../../helpers/types';
import { ControlledField } from '../../helpers/ControlledField';

export const Dropdown = React.forwardRef<HTMLButtonElement>(
  (
    {
      id,
      name,
      value,
      control,
      isRequired,
      variant = 'default',
      errorText,
      requiredLabelText = (isReq, label) => (isReq ? label : `${label} (optional)`),
      labelText,
      helpText,
      placeholderText,
      isDisabled,
      items,
      isInFieldPanel,
      onChangeValue,
      hideLabel = false
    }: Props,
    /* @ts-ignore */
    // eslint-disable-next-line no-unused-vars
    ref
  ) => {
    const inFieldPanel = useFieldPanelContext();
    return (
      <ControlledField
        render={({ field }) => {
          return (
            <BaseDropdown
              id={id}
              disabled={isDisabled}
              errorText={errorText}
              helpText={helpText}
              /* @ts-ignore */
              ref={ref}
              variant={
                // eslint-disable-next-line no-nested-ternary
                variant === 'inline'
                  ? 'inline'
                  : inFieldPanel || isInFieldPanel
                  ? 'light'
                  : 'default'
              }
              dropdownLabel={placeholderText ?? ''}
              labelText={requiredLabelText(!!isRequired, labelText ?? '')}
              hideLabel={hideLabel}
              initialSelectedItem={items.find(c => c.value === field.value)}
              selectedItem={items.find(c => c.value === field.value) ?? null}
              items={items}
              itemToString={c => c.name}
              onChange={e => field.onChange(e!.value)}
              data-cy="expand-dropdown"
            />
          );
        }}
        onChangeValue={onChangeValue}
        control={control}
        name={name}
        value={value ?? ''}
      />
    );
  }
);

type Props = ControlledFieldProps<string> & {
  // *******************************************************************
  // Common properties for all fields
  requiredLabelText?: (isReq: boolean, label: string) => string;
  isRequired?: boolean;

  // *******************************************************************
  // Dropdown specific properties

  placeholderText?: string;
  items: Item[];
  variant: 'default' | 'inline';
  hideLabel?: boolean;
};

type Item = {
  name: string;
  value?: string;
};
