/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

export const formatToDateTime = d => {
  const finalDate = d.split('/');
  return `${finalDate[2] + '-' + finalDate[0] + '-' + finalDate[1]}`;
};

export const formatToIso = d => {
  return new Date(formatToDateTime(d)).toISOString();
};
