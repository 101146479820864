/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import styled, { css } from 'styled-components';
import { media } from '@exo/frontend-common-style-utils';
import theme from './Footer.theme';

export const Footer = styled('footer')`
  background: ${props => theme(props).background};
  display: flex;
  flex-direction: column;
  height: 6rem;
  ${props =>
    media.lessThan(props, 'large').then(css`
      height: 3.438rem;
    `)}
`;

export const Social = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 8rem;
  padding: ${props => props.theme.spacing.inset.M};
  border-width: ${props => theme(props).social__borderWidth};
  border-color: ${props => theme(props).social__borderColor};
  border-style: solid;
  ${props =>
    media.greaterThan(props, 'small').then(css`
      padding: ${props.theme.spacing.stack.sXL} ${props.theme.spacing.inline.sXL}
        ${props.theme.spacing.stack.sM};
    `)}
  ${props =>
    media.greaterThan(props, 'medium').then(css`
      flex-direction: row;
    `)};
  ${props =>
    media.lessThan(props, 'large').then(css`
      height: 3.438rem;
    `)}
`;

export const Content = styled('div')`
  background: ${props => theme(props).contentBackground};
  color: ${props => theme(props).contentColor};
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  ${props =>
    media.lessThan(props, 'large').then(css`
      padding: 0rem 1rem;
    `)}
`;

export const Columns = styled('div')`
  display: grid;
  grid-gap: ${props => props.theme.spacing.inline.sM};
  grid-template-areas: 'section1 section2';
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 1366px) {
    grid-template-columns: 1.275fr 1fr;
  }
  @media screen and (max-width: 1080px) {
    grid-template-columns: 1.4fr 1fr;
  }
`;

export const Link = styled('span')`
  /* stylelint-disable selector-max-type */
  a {
    color: ${props => theme(props).linkColor};
    font: ${props => theme(props).linkFont};
    font-size: 0.75rem;
    outline: none;
    transition: opacity 200ms ease-out;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`;

export const Column = styled('div')`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &:hover,
  &:focus,
  &:focus-within {
    & ${Link} {
      opacity: 0.5;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }
  ${props =>
    media.lessThan(props, 'large').then(css`
      height: 3.438rem;
    `)}
`;

export const Heading = styled('h2')`
  font: ${props => theme(props).headingFont};
  text-align: left;
`;

export const Items = styled('ul')`
  list-style: none;
`;

export const Item = styled('li')`
  margin: ${props => props.theme.spacing.stack.sXXS} 0 0;
`;

export const Terms = styled('div')`
  background: ${props => theme(props).termsBackground};
  color: ${props => theme(props).termsColor};
  font: ${props => theme(props).termsFont};
  padding: ${props => props.theme.spacing.stack.sXS} ${props => props.theme.spacing.inline.sXL};
  width: 100%;
`;

export const Section1 = styled('div')`
  grid-area: section1;
  max-width: 50rem;
  padding-top: 0.5rem;
  text-align: left;
  color: ${props => theme(props).termsColor};
  & ${Item} {
    color: ${props => theme(props).linkColor};
    line-height: 1.2;

    &:first-child {
      font-weight: bold;
      padding-bottom: 0.3rem;
    }

    & a {
      color: ${props => theme(props).linkPurple};
      text-decoration: underline;
    }
  }

  ${props =>
    media.lessThan(props, 'large').then(css`
      padding-bottom: 0.25rem;
      padding-top: 0.25rem;
      & ${Item} {
        font-size: 0.75rem;
        line-height: 1.2;

        &:first-child {
          font-size: 0.75rem;
          font-weight: bold;
          padding-bottom: 0rem;
        }
      }
    `)}
`;

export const Section2 = styled('div')`
  align-content: center;
  display: flex;
  grid-area: section2;
  justify-content: flex-end;
  text-align: right;
  & ${Items} {
    width: 15.625rem;
  }
  & ${Item} {
    color: ${props => theme(props).linkGray};
    display: inline-block;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;

    &:not(:last-child):not(:nth-child(2)):after {
      content: ' | ';
    }
  }

  ${props =>
    media.lessThan(props, 'large').then(css`
      & ${Items} {
        margin-top: 0.5rem;
        width: 16rem;
      }

      & ${Item} {
        padding: 0;

        & a {
          color: ${theme(props).linkColor};
          font-size: 0.75rem;
        }
      }
    `)}
`;
