/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useQuery } from '@apollo/client';

export const GET_MENU_ITEMS = gql`
  query GetMenuItems($id: String!) {
    navRoot(id: $id) {
      children {
        __typename
        ... on NavContentNode {
          ...ContentNode
          children {
            __typename
            ... on NavContentNode {
              ...ContentNode
              children {
                __typename
                ... on NavContentNode {
                  ...ContentNode
                }
              }
            }
          }
        }
      }
    }
  }

  fragment ContentNode on NavContentNode {
    __typename
    title
    thumbnail
    description
    type
    link {
      __typename
      label
      ... on NavCategoryLink {
        category {
          id
          identifier
          slug
        }
      }
      ... on NavUrlLink {
        url
      }
    }
  }
`;

export type NavContentNode = {
  __typename: string;
  title: string;
  thumbnail?: string;
  description?: string;
  type?: string;
  link: {
    __typename: string;
    label: string;
    url: string;
    category?: {
      id: string;
      identifier: string;
      slug: string;
    };
  };
  children?: NavContentNode[];
};

export const useNavigation = (id: string) => {
  const { loading, error, data } = useQuery<{ navRoot: { children: NavContentNode[] } }>(
    GET_MENU_ITEMS,
    {
      variables: { id }
    }
  );

  return { loading, error, data };
};
