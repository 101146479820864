/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useMutation } from '@apollo/client';
import { handleApolloError } from '@exo/frontend-common-apollo';

export const DELETE_SERVICE = gql`
  mutation DeleteService($favouriteServiceId: ID!) {
    deleteFavouriteService(favouriteServiceId: $favouriteServiceId) 
  }
`;

export const useDeleteService = () => {
    const [deleteService, { data, loading, error }] = useMutation(DELETE_SERVICE, {
        onError(err) {
            if (process.env.NODE_ENV === 'development' || process.env.JEST_WORKER_ID !== undefined) {
                // eslint-disable-next-line no-console
                console.error(`Error ${__filename}: ${JSON.stringify(err, undefined, '  ')}`);
            }
            return err;
        }
    });

    handleApolloError(__filename, error);
    return {
        deleteService: async (favouriteServiceId) => {
            return deleteService({
                variables: { favouriteServiceId }
            });
        },
        data,
        loading,
        error
    };
};


