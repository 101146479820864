/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React from 'react';
import * as S from './PageNotFound.styles';
import { usePolarisSessionContext } from '@exo/frontend-common-polaris-session-context';
import { useIntl } from '@exo/frontend-common-i18n';

export const PageNotFound = () => {
  const intl = useIntl('features.insurance.pageNotFound');

  const session = usePolarisSessionContext();
  let link: string = '/my-home';
  if (!session?.roles?.length) {
    link = '/';
  }

  return (
    <S.PageNotFound>
      <S.Container>
        <S.Text>
          {intl.msg('text1', "We're sorry we can't find the page you are looking for.") as string}
        </S.Text>
        <S.Text className="last">
          {
            intl.msg('text2', 'To go to the imarket homepage, click <a>here</a>', {
              a: str => <S.Link href={link}>{str}</S.Link>
            }) as string
          }
        </S.Text>
      </S.Container>
    </S.PageNotFound>
  );
};
