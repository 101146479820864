/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import styled from 'styled-components';
import theme from './LoadingIndicator.theme';

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;

  .purple-loading-spinner {
    & .bx--loading__stroke {
      stroke: ${props => theme(props).strokeColor};
    }
  }

  .bx--loading-overlay {
    background-color: ${props => theme(props).bgColor};
  }
`;
