/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/
import styled from 'styled-components';
import { ContentWrapper } from '@exo/frontend-components-insurance';
import { textMedium } from '@exo/frontend-common-style-utils';
import theme from './GeneralErrorPage.theme';

export const PageWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-between;
  margin: 5rem auto 0;
  min-height: 9.5rem;
  width: 31.5rem;
`;

export const Content = styled('div')`
  color: ${props => theme(props).errorRed};
  margin: 0.5rem 0;
  width: 100%;
  ${textMedium}
`;

export const Message = styled('div')``;

export const Footer = styled('div')`
  align-items: center;
  display: flex;
  height: fit-content;
  justify-content: flex-start;
  width: 100%;

  & .btn-no-padding {
    color: ${props => theme(props).btnPurple};
  }
`;
