/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

export enum NoYesEnum {
  N = 'N',
  Y = 'Y'
}

export enum UserTypeName {
  POLARIS_INSURER_ADMIN = 'Polaris Insurer Administrator',
  INSURER_GROUP_ADMIN = 'Insurer Group Administrator',
  INSURER_ADMIN = 'Insurer Administrator',
  AGENCY_INSURER_USER = 'Agency Insurer User',
  LIVE_CHAT_INSURER_USER = 'Live Chat Insurer User',
  PUBLISHING_INSURER_USER = 'Publishing Insurer User',
  POLARIS_ADMIN = 'Polaris Administrator',
  INTERMEDIARY_GROUP_ADMIN = 'Intermediary Group Administrator',
  INTERMEDIARY_ADMIN = 'Intermediary Administrator',
  INTERMEDIARY_USER = 'Intermediary User',
  FTP_INSURER_USER = 'FTP Insurer User',
  POLARIS_INTERMEDIARY_ADMIN = 'Polaris Intermediary Administrator',
  AMS_USER = 'AMS User',
  INSURER_USER = 'Insurer User',
  APPLICATION_ENDPOINT = 'Application EndPoint',
  APPLICATION_USE = 'Application Use'
}
