/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import styled, { css } from 'styled-components';
import theme from './Chrome.theme';
import { media } from '@exo/frontend-common-style-utils';

export const Chrome = styled('div')`
  background-color: ${props => theme(props).pageBgColor};
  display: flex;
  flex-direction: column;
  min-width: 64rem;
  position: relative;
`;

export const Footer = styled('div')`
  width: 100%;
`;

export const HeaderSection = styled('div')`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 5;
  ${props =>
    media.lessThan(props, 'large').then(css`
      height: 2rem;
    `)}
  ${props =>
    media.greaterThan(props, 'large').then(css`
      min-height: 3rem;
    `)}
`;

export const Notifications = styled('div')`
  max-height: 100vh;
  overflow: auto;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 5;
`;

export const Main = styled('div')<{ showError: boolean }>`
  background-color: ${props => theme(props).contentBgColor};
  display: ${props => (props.showError ? 'none' : 'flex')};
  flex: 1;
  justify-content: center;
  min-height: calc(100vh - 3.375rem - 6rem);
  padding: ${props => theme(props).contentPadding};
  ${props =>
    media.lessThan(props, 'large').then(css`
      min-height: calc(100vh - 2rem - 3.938rem);
      overflow-x: auto;
    `)}
`;

export const LoadingContainer = styled('div')`
  background-color: ${props => theme(props).loadingBgColor};
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
`;

export const ErrorComponentWrapper = styled('div')`
  min-height: calc(100vh - 3rem - 6rem);
`;
