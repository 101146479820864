/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

/*
    param: input from form
    return: [{key{n}_columnName: {Column Name}, value{n}: {input value}}]
*/
export const composeSearchKeys = (input) => {
    const composedSearchKeys = [] as any
    let counter = 1 // not using index as it starts from 0
    for (const [key, value] of Object.entries(input)) {
        const obj = {}
        obj[`key${counter}_columnName`] = key
        obj[`value${counter}`] = value
        counter++
        composedSearchKeys.push(obj)
    }
    return composedSearchKeys
}