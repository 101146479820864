/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { Level1Item } from '@exo/frontend-components-insurance';
import { slugIt } from '../../utils/utils';

export const parseTree = root => {
  const items = {
    items: [] as Level1Item[]
  } as Root;
  for (const parentItem of root?.children!) {
    const parentSlug = slugIt(parentItem?.functionName);
    const obj = {
      id: parentItem.nodeId,
      title: parentItem.functionName,
      url: `/products-services/service/${parentSlug}`, // TODO to be updated for every scenario
      isProdServices: true,
      type: parentItem.tabPriority
    } as Level1Item;

    if (parentItem?.children?.length > 0) {
      const children = [] as ChildItem[];
      for (const item of parentItem.children) {
        const slug = slugIt(item.functionName);
        const child = {
          label: item.functionName,
          url: `/products-services/service/${parentSlug}/${slug}`, // TODO to be updated for every scenario
          type: item.tabPriority,
          parentName: item.parentName,
          id: item.nodeId
        };
        children.push(child);
      }
      obj.items = children;
    }
    items.items.push(obj);
  }
  return items;
};

export type Root = {
  title?: string;
  items: Level1Item[];
};

export type ParentItem = {
  id?: string;
  title: string;
  url: string;
  items: ChildItem[];
};

type ChildItem = {
  label: string;
  url: string;
  items?: ChildItem[];
  type?: string;
  parentName?: string;
  id: string;
};
