/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React, { useEffect } from 'react';
import { errorSubject } from '@exo/frontend-common-polaris-error';
import { useHistory } from 'react-router-dom';
import {
  isUnauthorized,
  isForbidden,
  isInternalError,
  isDataFetchingException,
  isGatewayTimeOut
} from './errorCodes';

export const DefaultError = ({ error }) => {
  const history = useHistory();
  useEffect(() => {
    if (isUnauthorized(error)) {
      history.replace('/home/unauthenticated');
    } else if (isForbidden(error)) {
      history.replace('/home/not-allowed');
    } else if (isInternalError(error)) {
      errorSubject.next(error);
    } else if (isDataFetchingException(error)) {
      errorSubject.next(error);
    } else if (isGatewayTimeOut(error)) {
      errorSubject.next(error);
    } else {
      errorSubject.next(error);
    }
  }, [error]);
  return <></>;
};

export const DefaultLoading = () => {
  return 'Loading...';
};

export const renderDefaultError = error => <DefaultError error={error} />;
export const renderDefaultLoading = () => <DefaultLoading />;
