/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { renderDefaultError, SmartComponentProps } from '@exo/frontend-common-utils';
import { renderPolarisLoading } from '@exo/frontend-features-insurance-chrome-logic';
import gql from 'graphql-tag';
import { useEffect } from 'react';
import { useBusinessTreeByFunction } from '../../hooks/useBusinessTreeByFunction';
import { useInsurersByFunction } from '../../hooks/useInsurersByFunction';
import { BusinessTreeResultOutput, InsurersByFunctionResultOutput } from '../../model/types';

export const ServiceTabsContainer = ({
  render,
  renderError = renderDefaultError,
  functionId,
  businessAreaId,
  insurerIds,
  selectedTab
}: Props) => {
  const InsurersByFunctionChildrenFragment = gql`
    fragment InsurersByFunctionChildrenFragment on InsurerTreeNodeOutput {
      nodeId
      nodeName
      type
      parentId
    }
  `;

  const BusinessTreeByFunctionChildrenFragment = gql`
    fragment BusinessTreeByFunctionChildrenFragment on BusinessTreeNodeOutput {
      nodeId
      businessAreaName
      businessAreaDescription
      parentId
      parentName
      __typename
    }
  `;

  const InsurersByFunctionContainerFragment = gql`
    fragment InsurersByFunctionContainer on InsurerTreeNodeOutput {
      nodeId
      nodeName
      type
      parentId
      children {
        ...InsurersByFunctionChildrenFragment
        children {
          ...InsurersByFunctionChildrenFragment
          children {
            ...InsurersByFunctionChildrenFragment
            children {
              ...InsurersByFunctionChildrenFragment
            }
          }
        }
      }
    }
  `;

  const BusinessTreeByFunctionContainerFragment = gql`
    fragment BusinessTreeByFunctionContainer on BusinessTreeNodeOutput {
      nodeId
      children {
        ...BusinessTreeByFunctionChildrenFragment
        children {
          ...BusinessTreeByFunctionChildrenFragment
          children {
            ...BusinessTreeByFunctionChildrenFragment
            children {
              ...BusinessTreeByFunctionChildrenFragment
            }
          }
        }
      }
      businessAreaName
      businessAreaDescription
      parentId
      parentName
      __typename
    }
  `;
  let skipBusinessCall = selectedTab === 0;
  let skipInsurrerCall = selectedTab !== 0;
  //tab===1

  const {
    loading: businessLoading,
    data: businessData,
    error: businessError
  } = useBusinessTreeByFunction<BusinessTreeResultOutput>(
    { functionId, insurerIds, skip: skipBusinessCall },
    [BusinessTreeByFunctionContainerFragment, BusinessTreeByFunctionChildrenFragment]
  );
  //tab===0
  const { loading, data, error } = useInsurersByFunction<InsurersByFunctionResultOutput>(
    { functionId, businessAreaId, skip: skipInsurrerCall },
    [InsurersByFunctionContainerFragment, InsurersByFunctionChildrenFragment]
  );

  const generalLoading = loading || businessLoading;
  useEffect(() => {
    return renderPolarisLoading(generalLoading);
  }, [generalLoading]);

  if (error || businessError) return renderError(error || businessError);
  return render({
    insurersTreeData: data,
    businessTreeData: businessData,
    errors: error,
    loading: generalLoading
  });
};

type ServiceTabsContainerRenderProps = {
  errors: any;
  insurersTreeData?: InsurersByFunctionResultOutput;
  businessTreeData?: BusinessTreeResultOutput;
  loading: boolean;
};

type Props = SmartComponentProps<{
  render: (props: ServiceTabsContainerRenderProps) => JSX.Element;
  functionId: string;
  businessAreaId: string;
  insurerIds: string[];
  selectedTab: number;
}>;
