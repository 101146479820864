/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/
import { ApolloError } from '@apollo/client';
import { useEffect } from 'react';
import { SmartComponentProps, renderDefaultError } from '@exo/frontend-common-utils';
import { renderPolarisLoading } from '@exo/frontend-features-insurance-chrome-logic';
import { ServiceStateType } from '@exo/frontend-common-insurance-mutation';
import {
  useServiceInfoSummary,
  ServiceInformationSummaryOutput
} from '../../hooks/useServiceInfoSummary';

export const ServiceInfoContainer = ({
  serviceId,
  levelOfService,
  render,
  renderError = renderDefaultError
}: Props) => {
  const { data, loading, error } = useServiceInfoSummary(serviceId, levelOfService);

  useEffect(() => {
    renderPolarisLoading(loading);
  }, [loading]);
  if (error) return renderError(error);

  return render({
    data,
    error
  });
};

type ServiceInfoContainerRenderProps = {
  data?: ServiceInformationSummaryOutput;
  error?: ApolloError;
};

type Props = SmartComponentProps<{
  serviceId: string;
  levelOfService: ServiceStateType;
  render: (props: ServiceInfoContainerRenderProps) => JSX.Element;
}>;
