/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved
US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import axios from 'axios';
import useAxios from 'axios-hooks';
import { sessionStorage } from '@exo/frontend-common-polaris-session-context';

const transformInput = (input: any) => {
  //the input will come in the form of { serviceIDServiceLEVEL:IBLReference,serviceIDServiceLEVEL:IBLReference }
  //we need to transform it to
  //   {
  //     "serviceKeys": [
  //         "5995L"
  //     ],
  //     "selectedIBLs": {
  //         "5953L": "DariusIBLT1"
  //     }
  // }
  let extractedServices: string[] = [];
  Object.keys(input).forEach(key => {
    if (key) extractedServices.push(key);
  });
  return {
    serviceKeys: [...extractedServices],
    selectedIBLs: { ...input }
  };
};

export const useInvokeInformation = polarisConfig => {
  const { host, informationEndpoint } = polarisConfig;

  axios.interceptors.request.use(
    async config => {
      const token = sessionStorage.get()?.token;

      if (token) {
        config.headers = {
          authorization: `Bearer ${token}`
        };
      }
      return config;
    },
    error => Promise.reject(error)
  );

  const [{ data, loading, error }, executeInvoke] = useAxios(
    {
      url: `${host}${informationEndpoint}`,
      method: 'POST',
      withCredentials: true
    },
    {
      manual: true
    }
  );

  const callInformation = body => {
    return executeInvoke({
      url: `${host}${informationEndpoint}`,
      data: transformInput(body)
    });
  };

  return {
    data,
    loading,
    error,
    callInformation
  };
};
