/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import styled from 'styled-components';
import theme from './ContentWrapper.theme';

export const ContentWrapper = styled.div`
  background-color: ${() => theme().bgColor};
  padding: 1rem 2rem 2rem;
  width: 40rem;
`;
