/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { ApolloError } from '@apollo/client';

export const isValidationError = (err: ApolloError): boolean => {
  return err?.graphQLErrors?.some(er => er.extensions?.classification === 'ValidationError');
};

export const extractTemplateValidationErrorMessage = (err: ApolloError): string => {
  if (err.graphQLErrors?.[0]?.extensions?.file) {
    return err.graphQLErrors?.[0]?.extensions?.file.join('<br>');
  }
  return (
    err?.graphQLErrors?.[0]?.extensions?.message ||
    err?.graphQLErrors?.[0]?.extensions?.templateFile ||
    err?.graphQLErrors?.[0]?.message ||
    'Validation Error'
  );
};
