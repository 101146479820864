/* eslint-disable no-restricted-syntax */
/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React from 'react';

export const Action = (props: Props) => {
  let className = 'action';
  if (props.isActive) className += ' active';
  if (props.isUserBlocked) className += ' blocked';
  if (props.href) {
    return (
      <a className={className} target={props.target} href={props.href} onClick={props.onClick}>
        {props.label}
        {props.icon && <div role="img">{props.icon}</div>}
      </a>
    );
  } else {
    return (
      <button className={className} onClick={props.onClick}>
        {props.label}
        {props.icon && <div role="img">{props.icon}</div>}
      </button>
    );
  }
};

type Props = {
  label?: string | React.ReactNode;
  icon?: React.ReactElement;
  href?: string;
  isActive?: boolean;
  onClick?: (e?: any) => void;
  target?: string;
  isUserBlocked?: boolean;
};
