/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useQuery } from '@apollo/client';
import { handleApolloError, State } from '@exo/frontend-common-apollo';

const transformResponse = <T>(data: any): T => {
  return data?.getServices;
};

export const GET_SERVICES = gql`
  query GetServices($functionId: ID, $insurersIds: [ID], $businessAreaId: ID, $keywords: String) {
    getServices(
      functionId: $functionId
      insurersIds: $insurersIds
      businessAreaId: $businessAreaId
      keywords: $keywords
    ) {
      insurerWebsites {
        serviceName
        serviceProviderShortName
        serviceId
        serviceType
        level
        hasInformation
        isNew
        website
        IBLs {
          iblId
          iblName
          iblReference
        }
      }
      imarketRequests {
        serviceName
        serviceProviderShortName
        serviceId
        serviceType
        templateId
        level
        hasInformation
        isNew
        IBLs {
          iblId
          iblName
          iblReference
        }
      }
      multiInsurerRequests {
        templateId
        services {
          serviceName
          serviceProviderShortName
          serviceId
          serviceType
          level
          hasInformation
          isNew
          IBLs {
            iblId
            iblName
            iblReference
          }
        }
      }
      tooManyResults
    }
  }
`;

export const useGetServices = <T>({
  functionId,
  insurersIds,
  businessAreaId,
  keywords
}: Args): Result<T> => {
  const variables = { functionId, insurersIds, businessAreaId, keywords };

  const { loading, data, error } = useQuery(GET_SERVICES, { variables });

  handleApolloError(__filename, error);

  return { loading, data: transformResponse(data), error };
};

type Args = {
  keywords?: string;
  functionId?: string;
  insurersIds?: string[];
  businessAreaId?: string;
};

type Result<T> = {
  data?: T;
} & State;
