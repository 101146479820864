/* eslint-disable @typescript-eslint/no-use-before-define */
/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import {
  InsurerTreeNodeOutput,
  BusinessTreeNodeOutput,
  ExtractTreeData,
  ExtractedBusinessNames
} from '../model/types';

const extractChildNode = (input, returnData: string[]) => {
  if (input.isSelected) {
    returnData.push(String(input.nodeId));
  }
  if (input.children && input.children.length) {
    input.children.forEach(childItem => {
      extractChildNode(childItem, returnData);
    });
  }
};

const extractInsurersChildNode = (
  input,
  returnData: string[],
  byPassIsSelected: boolean = false
) => {
  if (input.isSelected || byPassIsSelected) {
    returnData.push(String(input.nodeId));
  }
  if (input.children && input.children.length) {
    input.children.forEach(childItem => {
      extractInsurersChildNode(childItem, returnData, byPassIsSelected);
    });
  }
};
export const extractSelected = (
  input: InsurerTreeNodeOutput[] | BusinessTreeNodeOutput[] | null
): ExtractTreeData => {
  const returnIds: string[] = [];
  if (input && Object.keys(input).length > 0) {
    input.forEach(inputItem => {
      if (inputItem?.isSelected) {
        returnIds.push(String(inputItem.nodeId));
      }
      if (inputItem?.children && inputItem?.children.length) {
        inputItem.children.forEach(childItem => {
          extractChildNode(childItem, returnIds);
        });
      }
    });
  }
  return returnIds;
};
export const extractSelectedInsurers = (
  input: InsurerTreeNodeOutput | BusinessTreeNodeOutput | null | undefined
): ExtractTreeData => {
  //input will contain also Top first le'ts check if Top it's selected
  const returnIds: string[] = [];
  if (!input) return returnIds;
  let byPassIsSelected: boolean = input?.isSelected === true;

  const children = input?.children;
  if (children) {
    children.forEach(inputItem => {
      if (inputItem?.isSelected || byPassIsSelected) {
        if (inputItem?.children && inputItem?.children.length) {
          //we asume it's the group and then we will get the children ID's
          inputItem.children.forEach(childItem => {
            returnIds.push(String(childItem.nodeId));
          });
        } else {
          //it means it's a child so we push it directly
          returnIds.push(String(inputItem.nodeId));
        }
      } else if (inputItem?.children && inputItem?.children.length) {
        inputItem.children.forEach(childItem => {
          extractInsurersChildNode(childItem, returnIds, byPassIsSelected);
        });
      }
    });
  }
  return returnIds;
};

const extractParentName = (
  input: BusinessTreeNodeOutput[],
  parentId: number,
  names: string[],
  childItem: BusinessTreeNodeOutput | null = null
) => {
  if (childItem) {
    if (Number(childItem?.nodeId) === parentId) {
      names.push(String(childItem.businessAreaName));
      if (childItem.parentId) {
        extractParentName(input, Number(childItem.parentId), names);
      }
    } else if (childItem.children && childItem.children.length) {
      childItem.children.forEach(child => {
        if (Number(child.nodeId) === parentId) {
          names.push(String(child.businessAreaName));
          if (Number(child?.nodeId) !== 0) {
            extractParentName(input, Number(child.parentId), names);
          }
        } else {
          extractParentName(input, parentId, names, child);
        }
      });
    }
  } else {
    input.forEach(inputItem => {
      if (Number(inputItem?.nodeId) === parentId) {
        names.push(String(inputItem.businessAreaName));
        if (Number(inputItem?.nodeId) !== 0) {
          extractParentName(input, Number(inputItem.parentId), names);
        }
      } else if (inputItem.children && inputItem.children.length) {
        inputItem.children.forEach(inputChildItem => {
          if (Number(inputChildItem.nodeId) === parentId) {
            names.push(String(inputChildItem.businessAreaName));
            extractParentName(input, Number(inputChildItem.parentId), names);
          } else {
            extractParentName(input, parentId, names, inputChildItem);
          }
        });
      }
    });
  }
};

const extractfromChildNode = (
  input: BusinessTreeNodeOutput[],
  child: BusinessTreeNodeOutput,
  names: string[]
) => {
  if (child.isSelected) {
    names.push(String(child.businessAreaName));
    extractParentName(input, Number(child.parentId), names);
  } else if (child.children && child.children.length) {
    child.children.forEach(childItem => {
      extractfromChildNode(input, childItem, names);
    });
  }
};
export const extractSelectedBusinessNames = (
  input: BusinessTreeNodeOutput[] | null
): ExtractedBusinessNames => {
  const names: string[] = [];
  if (input) {
    input.forEach(inputItem => {
      if (inputItem?.isSelected) {
        names.push(String(inputItem.businessAreaName));
        if (Number(inputItem?.nodeId) !== 0) {
          extractParentName(input, Number(inputItem.parentId), names);
        }
      } else if (inputItem?.children && inputItem?.children.length) {
        inputItem.children.forEach(childItem => {
          extractfromChildNode(input, childItem, names);
        });
      }
    });
  }
  return names;
};

export const isObjectEmpty = objectName => {
  return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;
};

export const openPopUpFn = (url: string, w: number = 500, h: number = 500) => {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  let width;
  let height;
  if (window.innerWidth) {
    width = window.innerWidth;
  } else if (document.documentElement.clientWidth) {
    width = document.documentElement.clientWidth;
  } else {
    width = screen.width;
  }

  if (window.innerHeight) {
    height = window.innerHeight;
  } else if (document.documentElement.clientHeight) {
    height = document.documentElement.clientHeight;
  } else {
    height = screen.height;
  }

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;

  window.open(
    url,
    '_blank',
    `
    popup=yes,
    scrollbars=yes,
    resizable=yes,
    top=${top},
    left=${left}
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
  `
  );
};
