/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/


import { gql, useQuery } from '@apollo/client';
import { handleApolloError, State } from '@exo/frontend-common-apollo';

const transformResponse = <T>(data: any): T => {
    return data?.favouriteSearches;
};

export const GET_FAVOURITE_SEARCHES = gql`   
query GetFavouriteSearches {
        favouriteSearches {
        favouriteSearchId
        favouriteSearchName
        functionId
        businessIds
        insurerIds
    }
}`

export const useFavouriteSearches = <T>(): Result<T> => {
    const { loading, data, error, refetch } = useQuery(GET_FAVOURITE_SEARCHES)

    handleApolloError(__filename, error);

    return { loading, data: transformResponse(data), error, refetch };
};

type Result<T> = {
    data?: T;
    refetch: () => void;
} & State;
