/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { renderDefaultError, SmartComponentProps } from '@exo/frontend-common-utils';
// import { renderPolarisLoading } from '@exo/frontend-features-insurance-chrome-logic';
import gql from 'graphql-tag';
// import { useEffect } from 'react';
import { useBusinessTreeByFunction } from '../../hooks/useBusinessTreeByFunction';
import { useInsurersByFunction } from '../../hooks/useInsurersByFunction';
import { BusinessTreeResultOutput, InsurersByFunctionResultOutput } from '../../model/types';

export const FavSearchContainer = ({
  render,
  renderError = renderDefaultError,
  functionId
}: Props) => {
  const InsurersByFunctionChildrenFragment = gql`
    fragment InsurersByFunctionChildrenFragment on InsurerTreeNodeOutput {
      nodeId
      nodeName
      type
      parentId
    }
  `;

  const BusinessTreeByFunctionChildrenFragment = gql`
    fragment BusinessTreeByFunctionChildrenFragment on BusinessTreeNodeOutput {
      nodeId
      businessAreaName
      businessAreaDescription
      parentId
      parentName
      __typename
    }
  `;

  const InsurersByFunctionContainerFragment = gql`
    fragment InsurersByFunctionContainer on InsurerTreeNodeOutput {
      nodeId
      nodeName
      type
      parentId
      children {
        ...InsurersByFunctionChildrenFragment
        children {
          ...InsurersByFunctionChildrenFragment
          children {
            ...InsurersByFunctionChildrenFragment
            children {
              ...InsurersByFunctionChildrenFragment
            }
          }
        }
      }
    }
  `;

  const BusinessTreeByFunctionContainerFragment = gql`
    fragment BusinessTreeByFunctionContainer on BusinessTreeNodeOutput {
      nodeId
      children {
        ...BusinessTreeByFunctionChildrenFragment
        children {
          ...BusinessTreeByFunctionChildrenFragment
          children {
            ...BusinessTreeByFunctionChildrenFragment
            children {
              ...BusinessTreeByFunctionChildrenFragment
            }
          }
        }
      }
      businessAreaName
      businessAreaDescription
      parentId
      parentName
      __typename
    }
  `;

  const {
    loading: businessLoading,
    data: businessData,
    error: businessError
  } = useBusinessTreeByFunction<BusinessTreeResultOutput>({ functionId }, [
    BusinessTreeByFunctionContainerFragment,
    BusinessTreeByFunctionChildrenFragment
  ]);

  const {
    loading: insurersLoading,
    data,
    error
  } = useInsurersByFunction<InsurersByFunctionResultOutput>({ functionId }, [
    InsurersByFunctionContainerFragment,
    InsurersByFunctionChildrenFragment
  ]);

  const loading = insurersLoading || businessLoading;

  // useEffect(() => {
  //   return renderPolarisLoading(loading || businessLoading);
  // }, [loading, businessLoading]);

  if (error || businessError) return renderError(error || businessError);
  return render({
    insurersTreeData: data,
    businessTreeData: businessData,
    errors: error,
    loading
  });
};

type FavSearchContainerRenderProps = {
  errors: any;
  insurersTreeData?: InsurersByFunctionResultOutput;
  businessTreeData?: BusinessTreeResultOutput;
  loading: boolean;
};

type Props = SmartComponentProps<{
  render: (props: FavSearchContainerRenderProps) => JSX.Element;
  functionId: string;
}>;
