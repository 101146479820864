/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import styled, { css } from 'styled-components';
import { Link as ReactLink } from '@exo/frontend-common-link';
import { ifProp } from '@exo/frontend-common-style-utils';
import theme from './Menu.theme';

export const Menu = styled('div')`
  width: 16rem;
  /* stylelint-disable selector-max-type */
  .selected-parent {
    & span {
      font-weight: 600;
    }

    & a {
      font-weight: 600;
    }
  }

  .no-child {
    background: ${props => theme(props).activeBackground};
    border-left: 0.25rem solid ${props => theme(props).noChildBorderColor};
    color: ${props => theme(props).activeText};
    padding: 0.438rem 0.9rem 0.375rem 0.8rem;
  }
`;

export const Header = styled('div')`
  align-items: center;
  background: ${props => theme(props).headerBackground};
  display: flex;
  height: 2rem;
  margin: 0.06rem 0;
  padding: 0.438rem 1rem 0.375rem 1rem;
`;

// eslint-disable-next-line no-restricted-syntax
export const TitleURL = styled('a')`
  color: ${props => theme(props).headerColor};
  cursor: pointer;
  display: block;
  flex: 1;
  font: 400 ${props => theme(props).menuFontSize} ${props => theme(props).fontFamily};
  line-height: 1;

  &:hover {
    color: ${props => theme(props).headerColorHover};
  }
`;

export const Title = styled('span')`
  color: ${props => theme(props).headerColor};
  cursor: pointer;
  display: block;
  flex: 1;
  font: 400 ${props => theme(props).menuFontSize} ${props => theme(props).fontFamily};
  line-height: 1;

  &:hover {
    color: ${props => theme(props).headerColorHover};
  }
`;

export const Icon = styled('button')`
  border: none;
  color: ${props => theme(props).headerColor};
  cursor: pointer;
  flex: 0 0 1.5rem;
  margin: auto 0 auto auto;

  &:hover {
    color: ${props => theme(props).headerColorHover};
  }
`;

export const Content = styled('div')<{ isExpanded?: boolean }>`
  background: ${props => theme(props).menuBgColor};
  display: none;

  ${props =>
    ifProp(props, 'isExpanded').then(css`
      display: block;
    `)}
`;

export const Items = styled('div')``;

// eslint-disable-next-line no-restricted-syntax
export const ALink = styled.a`
  color: ${props => theme(props).color};
  cursor: pointer;
  display: block;
  font: 400 ${props => theme(props).linkFontSize} ${props => theme(props).fontFamily};
  padding: 0.5rem 1.5rem 0.313rem 2rem;

  &:hover {
    background: ${props => theme(props).activeBackground};
    color: ${props => theme(props).hoverColor};
  }

  &.isActive {
    background: ${props => theme(props).activeBackground};
    border-left: 0.25rem solid ${props => theme(props).activeBorderColor};
    color: ${props => theme(props).activeText};
    padding-left: 1.75rem;

    &:hover {
      background: ${props => theme(props).activeBackground};
      color: ${props => theme(props).activeText};
    }
  }
`;

export const Link = styled(ReactLink)`
  color: ${props => theme(props).color};
  cursor: pointer;
  display: block;
  font: 400 ${props => theme(props).linkFontSize} ${props => theme(props).fontFamily};
  padding: 0.5rem 1.5rem 0.313rem 2rem;

  &:hover {
    background: ${props => theme(props).activeBackground};
    color: ${props => theme(props).hoverColor};
  }

  &.isActive {
    background: ${props => theme(props).activeBackground};
    border-left: 0.25rem solid ${props => theme(props).activeBorderColor};
    color: ${props => theme(props).activeText};
    padding-left: 1.75rem;

    &:hover {
      background: ${props => theme(props).activeBackground};
      color: ${props => theme(props).activeText};
    }
  }
`;
