/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import styled, { css } from 'styled-components';
import { media } from '@exo/frontend-common-style-utils';

export const DashboardPageContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100vw;

  overflow: hidden;
`;

export const DashboardPageMenu = styled.div`
  background-color: #fff;
  padding-top: 0.5rem;
  width: 16rem;

  height: 100%;
`;

export const DashboardPageContent = styled.div`
  padding: 1rem 0 0 2rem;
  width: 100%;
  ${props =>
    media.lessThan(props, 'large').then(css`
      padding: 1rem 0 0 1rem;
    `)}
  height:100%;
  overflow-y: hidden;
`;
