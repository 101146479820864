/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { loadingSubject } from '@exo/frontend-common-polaris-loading';

export const renderPolarisLoading = loading => {
  loadingSubject.next(loading);
};
