/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React from 'react';

export const AppErrorRenderer = ({ error, resetErrorBoundary }: Props) => {
  return (
    <div>
      <p>
        Unfortunately an error occured. Please raise a github issue if this occurs frequently
        <span role="img" aria-label="sad">
          😢
        </span>
      </p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary} type="button">
        Try again
      </button>
    </div>
  );
};

type Props = {
  error: any;
  resetErrorBoundary: () => void;
};
