/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React from 'react';
import { Loading, InlineLoading } from 'carbon-components-react';
import * as S from './LoadingIndicator.styles';

export const LoadingIndicator = ({
  label,
  type = 'fullscreen',
  className,
  useOverlay = false
}: Props) => {
  if (type === 'fullscreen') {
    return (
      <S.LoadingContainer>
        <Loading withOverlay={useOverlay} className={className} />
      </S.LoadingContainer>
    );
  } else {
    return <InlineLoading description={label} className={className} />;
  }
};

type Props = {
  type?: 'fullscreen' | 'inline';
  label?: string;
  className?: string;
  useOverlay?: boolean;
};
