/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import styled from 'styled-components';
import theme from './Field.theme';

export const Field = styled('div')`
  margin-bottom: ${props => theme(props).margin};

  & .bx--text-input__readonly-icon {
    display: none
  }
`;
