/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { renderDefaultError, SmartComponentProps } from '@exo/frontend-common-utils';
import { useEffect } from 'react';
import { useDeleteSearch } from '../../hooks/useDeleteFavouriteSearch';
import { useFavouriteSearches } from '../../hooks/useFavouriteSearches';
import { renderPolarisLoading } from '@exo/frontend-features-insurance-chrome-logic';
import { SuccessType } from '../../model/types';

export const SearchesTabContainer = ({
  render,
  renderError = renderDefaultError,
  event
}: Props) => {
  const { data, loading, error, refetch } = useFavouriteSearches<FavouriteSearch[]>();
  const { deleteSearch, error: submitError, loading: deleteLoading } = useDeleteSearch();

  useEffect(() => {
    refetch();
  }, [event?.timestamp]);

  useEffect(() => {
    renderPolarisLoading(loading || deleteLoading);
  }, [loading, deleteLoading]);
  if (error || submitError) return renderError(error || submitError);

  const onDelete = favouriteSearchId => {
    deleteSearch(favouriteSearchId).then(rsp => {
      if (rsp.data.deleteFavouriteSearch) refetch();
    });
  };

  return render({
    onDelete: onDelete,
    items: data!
  });
};

type SearchesTabContainerRenderProps = {
  onDelete: (favouriteSearchId: string) => void;
  items: FavouriteSearch[];
};

type FavouriteSearch = {
  favouriteSearchId: string;
  favouriteSearchName: string;
  functionId: string;
  businessIds: string[];
  insurerIds: string[];
};

type Props = SmartComponentProps<{
  render: (props: SearchesTabContainerRenderProps) => any;
  event?: SuccessType;
}>;
