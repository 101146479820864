/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

type Props = {
  background: string;
  contentBackground: string;
  contentColor: string;
  headingFont: string;
  linkColor: string;
  linkFont: string;
  termsBackground: string;
  termsColor: string;
  termsFont: string;
  social__borderWidth: string;
  social__borderColor: string;
  linkPurple: string;
  linkGray: string;
};

declare global {
  interface EXOComponentStyles {
    core_footer?: Partial<Props>;
  }
}

export default (props: EXOThemeProps): Props => {
  return {
    // Default theme
    background: '#714e9d', //props.theme.colors.backgrounds.panels.primary.base,
    contentBackground: props.theme.colors.backgrounds.panels.tertiary.base,
    contentColor: props.theme.colors.text.secondary,
    headingFont: `700 ${props.theme.typography.body.short.M.size} ${props.theme.typography.body.short.M.family}`,
    linkColor: '#ffffff', //props.theme.colors.text.secondary,
    linkFont: `400 ${props.theme.typography.body.short.M.size} ${props.theme.typography.body.short.M.family}`,
    termsBackground: props.theme.colors.brand.brand2.base,
    termsColor: props.theme.colors.inverse.base.fg,
    termsFont: `400 0.75rem ${props.theme.typography.body.short.M.family}`,
    social__borderColor: 'none',
    social__borderWidth: 0,
    linkPurple: '#e8aaff',
    linkGray: '#cbcbcb',

    ...props.theme?.core?.footer,
    ...props.theme?.byComponent?.core_footer
  };
};
