/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useQuery } from '@apollo/client';
import { handleApolloError, State } from '@exo/frontend-common-apollo';

const transformResponse = <T>(data: any): T => {
  return data?.formNavigator;
};

export const FORM_NAVIGATOR = gql`
  query FormNavigator($templateId: ID!) {
    formNavigator(templateId: $templateId) {
      title
      anchor
      childs {
        title
        anchor
      }
    }
  }
`;

export const useFormNavigator = <T>({ templateId }: Args): Result<T> => {
  const variables = { templateId };
  const { loading, data, error } = useQuery(FORM_NAVIGATOR, {
    variables,
    skip: templateId === '0'
  });

  handleApolloError(__filename, error);

  return { loading, data: transformResponse(data), error };
};

type Args = {
  templateId: string;
};

type Result<T> = {
  data?: T;
} & State;
