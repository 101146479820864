/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { renderDefaultError, SmartComponentProps } from '@exo/frontend-common-utils';
import { useEffect, useState } from 'react';
import { addTemplateInfo, extractTitle, parseXForm } from './XFormsHelper';
import { IService, useServiceContext } from '../../ServiceContext';
// import { usePolarisSessionContext } from '@exo/frontend-common-polaris-session-context';
import useAxios from 'axios-hooks';
import { renderPolarisLoading } from '@exo/frontend-features-insurance-chrome-logic';
import axios from 'axios';
import { sessionStorage } from '@exo/frontend-common-polaris-session-context';
import { useHistory } from 'react-router-dom';

// @ts-ignore
export const XFormsContainer = ({ render, renderError = renderDefaultError, config }: Props) => {
  const [title, setTitle] = useState('');
  const [html, setHtml] = useState('');
  const serviceContext = useServiceContext();
  const [context, setContext] = useState<IService>();
  const history = useHistory();

  axios.interceptors.request.use(
    async configX => {
      const token = sessionStorage.get()?.token;

      if (token) {
        configX.headers = {
          authorization: `Bearer ${token}`
        };
      }
      return configX;
    },
    error => Promise.reject(error)
  );

  const [{ loading, error }, executePost] = useAxios(
    {
      url: `${config.host}${config.displayTemplateEndpoint}`,
      method: 'POST',
      withCredentials: true
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    // force loading to be displayed until current query return false
    // otherwise canceled by other components from page finishing loading faster
    const interval = setInterval(() => {
      renderPolarisLoading(loading);
    }, 50);
    if (!loading) clearInterval(interval)
    renderPolarisLoading(loading);
    return () => {
      clearInterval(interval)
    }
  }, [loading]);

  useEffect(() => {
    serviceContext?.get().then(data => {
      setContext(data);
    }).catch(err => console.error('Something went wrong: ', err.message));
  }, []);

  if (context && Object.keys(context).length === 0) {
    history.push('/products-services');
  }

  useEffect(() => {
    if (context && Object.keys(context).length !== 0) {
      const selectedIBLs = Object.keys(context?.xFormData).reduce((obj, key) => {
        obj[key] = context?.xFormData[key];
        return obj;
      }, {});
      executePost({
        data: {
          // dynamic data, to be implemented after ldap is removed. do not remove!
          serviceKeys: Object.keys(context?.xFormData),
          selectedIBLs: selectedIBLs
        }
      }).then(async data => {
        setTitle(extractTitle(data.data));
        let parsedHTML = addTemplateInfo(
          data.data,
          'serviceKeys',
          JSON.stringify(Object.keys(context?.xFormData))
        );
        parsedHTML = addTemplateInfo(parsedHTML, 'selectedIBLs', JSON.stringify(selectedIBLs));
        const newHtml = parseXForm(parsedHTML);

        setHtml(newHtml);
      }).catch(err => {
        console.error('Something went wrong: ', err.message)
      });
    }
  }, [context]);

  useEffect(() => {
    if (html && html !== '') {
      document.dispatchEvent(
        new CustomEvent('navlinks', {
          detail: { navlinks: true }
        })
      );
    }
  }, [html]);

  if (error) return renderError(error);

  return render({
    html,
    title
  });
};

type XFormsContainerRenderProps = {
  html: string;
  title: string;
};

type Props = SmartComponentProps<{
  render: (props: XFormsContainerRenderProps) => any;
  config: {
    host: string;
    displayTemplateEndpoint: string;
  };
  inputData: string;
}>;
