/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { SmartComponentProps } from "@exo/frontend-common-utils";
import { renderPolarisLoading } from "@exo/frontend-features-insurance-chrome-logic";

export const LoadDataFromFileContainer = ({
    render,
    // renderError = renderDefaultError,
    onSuccessUpload,
    onError
}: Props) => {
    
    const onChange = async (data) => {
        renderPolarisLoading(true);
        await new Promise(resolve => setTimeout(resolve, 200));
        const file = data?.target?.files[0]
        if(!['text/xml'].includes(file.type)){
            onError('invalid')
            renderPolarisLoading(false);
            return;
        }

        let reader = new FileReader()
        reader.readAsText(file)
        reader.onload = () => {
            renderPolarisLoading(false);
            if(!reader?.result){
                onError('empty')
            }
            if (reader?.result) {
                onSuccessUpload(reader.result?.toString())
            }
        }
    }

    return render({
        onChange
    })
}

type LoadDataFromFileProps = {
    onChange: (data: any) => void;
}

type Props = SmartComponentProps<{
    render: (props: LoadDataFromFileProps) => any;
    onSuccessUpload: (xmlString: string) => void;
    onError: (error: string) => void;
}>;