/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React from 'react';
import * as S from './LoadingTile.styles';
import { LoadingIndicator } from '@exo/frontend-components-base';

export const LoadingTile = React.memo(({ isShown }: { isShown: boolean }) => {
  return (
    <S.Container className={isShown ? 'show' : 'hide'} data-testid="loading">
      <S.Load>
        <S.LoadingContainer>
          <S.Text>Please wait while data is loading...</S.Text>
          <LoadingIndicator useOverlay={false} className="purple-loading-spinner" />
        </S.LoadingContainer>
      </S.Load>
    </S.Container>
  );
});
