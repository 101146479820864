/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { renderDefaultError, SmartComponentProps } from '@exo/frontend-common-utils';
import { renderPolarisLoading } from '@exo/frontend-features-insurance-chrome-logic';
import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import { LongListRequestData, LongListResults } from '../../helper/types';
import { composeSearchKeys } from './XFormsLongListHelper';

export const XFormsLongListContainer = ({
  render,
  renderError = renderDefaultError,
  onSuccess,
  data,
  longListEndpoint,
  objRef
}: Props) => {
  const [searchState, setSearchState] = useState(undefined);
  const [searchQuery, setSearchQuery] = useState(undefined);

  const [{ loading: postLoading, error: postError }, executePost] = useAxios(
    {
      url: longListEndpoint,
      method: 'POST',
      withCredentials: true
    },
    { manual: true }
  );

  const longListQuery = (mode: string, query?: any) => {
    executePost({
      data: {
        mode: mode,
        listOwner: data.listOwner,
        listNo: data.listNo,
        shortDescIsKey: data.shortDescIsKey,
        caption: data.caption,
        displayField: 'e',
        showExpired: data.showExpired,
        longListId: data.id,
        search: {
          searchKeys: query ? query : searchQuery,
          searchState: mode !== 'INITIAL' ? searchState : null
        }
      }
    }).then(rsp => {
      if (rsp?.data?.results) {
        setSearchState(rsp?.data?.searchState);
        onSuccess(rsp?.data?.results);
      }
    });
  };

  const onSubmit = input => {
    const composedSearchKeys = composeSearchKeys(input);
    setSearchQuery(composedSearchKeys);
    longListQuery('INITIAL', composedSearchKeys);
  };

  const onNext = () => {
    longListQuery('NEXT', searchQuery);
    const domObj = document.getElementsByClassName(objRef?.current?.classList?.[0])[0];
    domObj.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  };

  const onPrev = () => {
    longListQuery('PREVIOUS', searchQuery);
    const domObj = document.getElementsByClassName(objRef?.current?.classList?.[0])[0];
    domObj.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  };

  useEffect(() => {
    renderPolarisLoading(postLoading);
  }, [postLoading]);

  if (postError) return renderError(postError);

  return render({
    onSubmit,
    onNext,
    onPrev
  });
};

type XFormsLongListRenderProps = {
  onSubmit: (input) => void;
  onNext: () => void;
  onPrev: () => void;
};

type Props = SmartComponentProps<{
  render: (props: XFormsLongListRenderProps) => any;
  onSuccess: (data: LongListResults) => void;
  data: LongListRequestData;
  longListEndpoint: string;
  objRef: any;
}>;
