/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import * as CSS from 'csstype';

type Props = {
  strokeColor: CSS.Property.Color;
  bgColor: CSS.Property.BackgroundColor;
};

declare global {
  interface EXOComponentStyles {
    insurance_account_login?: Partial<Props>;
  }
}

export default (props: EXOThemeProps): Props => {
  return {
    strokeColor: props.theme.colors.text.purple,
    bgColor: props.theme.colors.backgrounds.page
  };
};
