/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React from 'react';
import * as S from './ContentWrapper.styles';

export const ContentWrapper = ({ children, className = '' }: Props) => {
  return <S.ContentWrapper className={className}>{children}</S.ContentWrapper>;
};

type Props = {
  children?: React.ReactNode;
  className?: string;
};
