/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import styled, { css } from 'styled-components';
import theme from './LoadingTile.theme';
import { media } from '@exo/frontend-common-style-utils';

export const Container = styled.div`
  background-color: ${props => theme(props).loadingBgColorTr};
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  &.show {
    display: flex;
  }
  &.hide {
    display: none;
  }
`;

export const LoadingContainer = styled('div')<{ isLoading?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  /* stylelint-disable-next-line */
  margin-top: 45vh;
  width: 100%;
  z-index: 15;
`;

export const Text = styled('p')`
  color: ${props => theme(props).textColor};
`;

export const Load = styled('div')`
  background-color: ${props => theme(props).loadingBgColor};
  margin-top: 3.375rem;
  width: 100%;

  ${props =>
    media.lessThan(props, 'large').then(css`
      margin-top: 2rem;
    `)}
`;

// ${props => props.isLoading && 'z-index: 15;background-color: rgba(244, 244, 244, 1);'}
