/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

export const hasYellowPagesService = (value: string) => (value === 'yes' ? 'Y' : 'N');

export const reverseHasYellowPagesService = input => (input === 'Y' ? 'yes' : 'no');

export const hasSuppressTicketTransmission = input => (input ? 'yes' : 'no');
