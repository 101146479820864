/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BusinessTreeResultOutput, InsurersByFunctionResultOutput } from './model/types';

export const ServiceContext = React.createContext<ServiceType | undefined>(undefined);

export interface IService {
  parentFunctionName?: string;
  clickedFunctionName?: string;
  businessAreaId?: string;
  businessAreaNames?: string[];
  selectedInsurers?: string[];
  preselectedInsurers?: string[];
  selectedBusiness?: string[];
  keywords?: string;
  functionType?: string | number;
  previousSelectedTab?: string | number;
  clickedFunctionId?: string;
  templateId?: string;
  templateLevel?: string;
  selectedIBLs?: any[];
  serviceKeys?: string[];
  xFormData?: any;
  favSearchData?: any;
  selectedFavTab?: number;
  insurersTreeData?: InsurersByFunctionResultOutput;
  businessTreeData?: BusinessTreeResultOutput;
}

type ServiceType = IService & {
  set: (data: Partial<IService>) => void;
  get: () => Promise<IService>;
};

export const ServiceProvider = ({ children }: ServiceProps) => {
  const [context, setContext] = useState<IService>({});

  const set = (newContext: IService) => {
    setContext(newContext);
  };

  const value = {
    ...context,

    get: (): Promise<IService> => {
      return Promise.resolve(context);
    },

    set: (attrs: Partial<IService>) => {
      set({ ...context, ...attrs });
    }
  };
  return <ServiceContext.Provider value={value}>{children}</ServiceContext.Provider>;
};

export const useServiceContext = () => {
  const location = useLocation();
  const context = React.useContext(ServiceContext);
  if (context === undefined && location.pathname.includes('products-services')) {
    throw new Error('useService must be used within ServiceProvider');
  }
  return context;
};

type ServiceProps = {
  children: any;
};
