/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { SmartComponentProps } from '@exo/frontend-common-utils';

export const SaveToFileContainer = ({ render }: Props) => {
  const saveToFile = () => {
    const txo = window.getFormattedTXO();
    if (txo.length == 0) {
      const evt = new CustomEvent('modal-open', {
        detail: {
          message: "Sorry but the file you're trying to save is empty",
          type: 0
        }
      });
      document.dispatchEvent(evt);
      return;
    }
    let downloadUrl = 'data:text/xml,' + txo;
    let link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'iMarketSavedData.xml';
    link.click();
    link.remove();
  };

  return render({
    saveToFile
  });
};

type SaveToFileProps = {
  saveToFile: (txo: string) => void;
};

type Props = SmartComponentProps<{
  render: (props: SaveToFileProps) => any;
}>;
