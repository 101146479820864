/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import * as R from 'ramda';

export const removeEmpty = (d: any): any =>
  R.pipe(R.map(R.trim), R.reject(R.either(R.isNil, R.isEmpty)))(d);

export const trimObj = (d: any): any => R.pipe(R.map(R.trim))(d);

export const removeEmptyStrings = (d: any): any => R.reject(R.equals(''))(d);

export const trimAndRemoveEmpty = (d: any): any => R.pipe(R.map(R.trim), R.reject(R.equals('')))(d);
