/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { useQuery, gql } from '@apollo/client';
import { handleApolloError, State } from '@exo/frontend-common-apollo';
import { ServiceStateType } from '@exo/frontend-common-insurance-mutation';

export const SERVICE_INFO_SUMMARY = gql`
  query ServiceInfomationSummary($serviceId: ID!, $levelOfService: ServicePublishStateEnum!) {
    serviceInfomationSummary(serviceId: $serviceId, levelOfService: $levelOfService) {
      serviceProviderShortName
      serviceName
      description
      availableFrom
      availableTo
    }
  }
`;

const transformResponse = <T>(data: any): T => {
  return { ...data?.serviceInfomationSummary };
};

export const useServiceInfoSummary = (
  serviceId: string,
  levelOfService: ServiceStateType
): Result => {
  const variables = { serviceId, levelOfService };

  const { loading, data, error } = useQuery<ServiceInformationSummaryOutput>(SERVICE_INFO_SUMMARY, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only'
  });

  handleApolloError(__filename, error);

  return {
    loading,
    data: transformResponse(data),
    error
  };
};

type Result = {
  data?: ServiceInformationSummaryOutput;
} & State;

export type ServiceInformationSummaryOutput = {
  serviceProviderShortName: string;
  serviceName: string;
  description: string;
  availableFrom: string;
  availableTo: string;
};
