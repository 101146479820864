/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved
US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

/**
 * Chrome component wraps main application content
 * at the top-most level
 */

import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { IntlProvider } from '@exo/frontend-common-i18n';
import {
  AppErrorBoundary,
  ChromeContainerRenderProps
} from '@exo/frontend-features-insurance-chrome-logic';
import MetaTags from 'react-meta-tags';
import * as S from './Chrome.styles';
import { Notifications } from '../Notifications/Notifications';
import { AppErrorRenderer } from '../AppErrorRenderer/AppErrorRenderer';
import { Header } from '../../smart-components/Header/Header';
import { Footer } from '../../smart-components/Footer/Footer';
import { ChromeConfig } from '../../chromeConfig';
import { ChromeCSS } from '../ChromeCSS/ChromeCSS';
import { LeftMenu } from '../../smart-components/LeftMenu/LeftMenu';
import { LeftMenu as Menu, LoadingTile } from '@exo/frontend-components-insurance';
import { loadingSubject } from '@exo/frontend-common-polaris-loading';
import { errorSubject } from '@exo/frontend-common-polaris-error';
import { concat, delay, of, switchMap } from 'rxjs';
import { GeneralErrorPage } from '../GeneralErrorPage/GeneralErrorPage';

const TIMEOUT: number = 180000;

export const Chrome = ({
  notifications,
  onRemoveNotification,
  config,
  styleLinks,
  children,
  displayLeftMenu,
  loadDynamicMenu = false,
  isAuth = false
}: ChromeContainerRenderProps<ChromeConfig> & { children: any } & {
  displayLeftMenu?: boolean;
  loadDynamicMenu?: boolean;
  isAuth?: boolean;
}) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [graphQLError, setGraphQLError] = useState<any>(false);
  const [isClient, setIsClient] = useState<boolean>(false);
  let setterDelay: any = null;
  const clearTimer = () => {
    if (setterDelay) {
      clearTimeout(setterDelay);
      setterDelay = -1;
    }
  };
  useEffect(() => {
    setIsClient(true);
    return () => {
      clearTimer();
    };
  }, []);
  loadingSubject
    .pipe(
      switchMap(loading =>
        concat(of(loading), of(false).pipe(delay(Number(config.loadingTimeout) || TIMEOUT)))
      )
    )
    .subscribe((isLoading: boolean) => {
      clearTimer();
      setterDelay = setTimeout(() => {
        setShowLoading(isLoading);
      }, 10);
    });

  errorSubject.subscribe((isError: any) => {
    setGraphQLError(isError);
  });

  return (
    <>
      <ChromeCSS />
      <MetaTags>
        <title>{config.meta.title}</title>
        <meta name="description" content={config.meta.title} />
        <meta name="charSet" charSet="utf-8" />
        <meta
          name="viewport"
          content="minimum-scale=1,initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <meta name="theme-color" content="black" />
        <link rel="icon" href={config.meta.icon} />
        {styleLinks.map(s => (
          <link rel="stylesheet" href={s} key={s} />
        ))}
      </MetaTags>
      {isClient && (
        <S.Chrome>
          <S.HeaderSection>
            <IntlProvider
              translations={[
                { lang: 'en', messages: loadable.lib(() => import('../../translations/en')) }
              ]}
            >
              <Header config={config} isAuth={isAuth} />
              <S.Notifications className="notifications-wrapper">
                <Notifications notifications={notifications} onRemove={onRemoveNotification} />
              </S.Notifications>
            </IntlProvider>
          </S.HeaderSection>
          {graphQLError && (
            <S.ErrorComponentWrapper>
              <GeneralErrorPage graphQLError={graphQLError} />
            </S.ErrorComponentWrapper>
          )}
          <S.Main showError={!!graphQLError}>
            <AppErrorBoundary fallbackComponent={AppErrorRenderer}>
              {displayLeftMenu ? (
                <LeftMenu
                  loadDynamicMenu={!loadDynamicMenu}
                  render={args => <Menu data={args.items}>{children}</Menu>}
                />
              ) : (
                <>{children}</>
              )}
            </AppErrorBoundary>
          </S.Main>
          <S.Footer>
            <IntlProvider
              translations={[
                { lang: 'en', messages: loadable.lib(() => import('../../translations/en')) }
              ]}
            >
              <Footer config={config} />
            </IntlProvider>
          </S.Footer>
        </S.Chrome>
      )}
      <LoadingTile isShown={showLoading} />
    </>
  );
};
