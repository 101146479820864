/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { IBLOutput, NoYesEnum, ServiceTypeEnum } from "@exo/frontend-common-insurance-mutation";

export type ID = string;

export type InsurersByFunctionArgs = {
  functionId: ID;
  businessAreaId?: ID;
  skip?: boolean;
};
export type BusinessTreeByFunctionArgs = {
  functionId: ID;
  insurerIds?: ID[];
  skip?: boolean;
};

export enum InsurerNodeTypeEnum {
  GROUP = 'GROUP',
  ORGANISATION = 'ORGANISATION'
}
export type InsurerTreeNodeOutput = {
  nodeId: ID;
  nodeName: string;
  type: InsurerNodeTypeEnum;
  parentId: ID;
  isSelected?: boolean;
  children: Array<InsurerTreeNodeOutput> | null;
};

export type InsurersByFunctionResultOutput = {
  getInsurersByFunction: InsurerTreeNodeOutput[];
};

export type BusinessTreeNodeOutput = {
  nodeId: number;
  children: Array<BusinessTreeNodeOutput> | null;
  businessAreaName: string;
  businessAreaDescription: string;
  parentId: string;
  isSelected?: boolean;
  parentName: string;
};

export type BusinessTreeResultOutput = {
  businessTreeOutput: BusinessTreeNodeOutput[];
  levels: number;
  functionName: string;
};

export type ExtractTreeData = string[];
export type ExtractedBusinessNames = string[];

export type TemplateConfig = {
  host: string;
  clickThruEndpoint: string;
  informationEndpoint: string;
  displayTemplateEndpoint: string;
  longListEndpoint: string;
};


export type MultiInsurerServiceOutput = {
  serviceName: string;
  serviceProviderShortName: string;
  serviceId?: ID;
  serviceType: ServiceTypeEnum;
  hasInformation?: NoYesEnum;
  isNew?: boolean;
  level?: string;
  IBLs?: IBLOutput[];
};

export type GetServicesResultOutput = {
  multiInsurerRequests: {
    templateId: ID;
    services: MultiInsurerServiceOutput[];
  }[];
  imarketRequests: {
    serviceName: string;
    serviceProviderShortName: string;
    serviceId: string;
    serviceType: ServiceTypeEnum;
    templateId?: ID;
    hasInformation: NoYesEnum;
    isNew?: boolean;
    level?: string;
    IBLs?: IBLOutput[];
  }[];
  insurerWebsites: {
    serviceName: string;
    serviceProviderShortName: string;
    serviceId: string;
    serviceType: ServiceTypeEnum;
    hasInformation?: NoYesEnum;
    isNew?: boolean;
    IBLs?: IBLOutput[];
    website: string;
    level?: string;
  }[];
  tooManyResults?: NoYesEnum;
};

export type ServiceInput = {
  serviceId: string;
  favouriteServiceName: string;
  serviceLevel: string;
};

export type SuccessType = {
  search?: string;
  service?: string;
  timestamp?: string;
};