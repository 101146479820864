/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/
import React from 'react';
import { Footer as FooterComponent } from '@exo/frontend-components-core';
import { ChromeConfig } from '../../chromeConfig';

// TODO: The contents should be brought in from the config or navigation
export const Footer = ({ config }: { config: ChromeConfig }) => {
  const intlPrefix = 'features.insurance.chrome.chrome-ui.smart-components.Footer'
  return (
    <FooterComponent
      links={{
        sectionsLeft: config.footer.sectionLeft,
        sectionsRight: config.footer.sectionRight
      }}
      intlPrefix={intlPrefix}
    />
  );
};
