/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import styled from 'styled-components';
import { responsiveFontBlock } from '@exo/frontend-common-style-utils';
import theme from './FieldPanel.theme';

export const Help = styled('p')`
  ${props => responsiveFontBlock(props.theme.typography.body.short.M)};
  margin-bottom: ${props => theme(props).margin};
`;

export const FieldPanel = styled('div')`
  background-color: ${props => props.theme.colors.backgrounds.panels.primary.base};
  margin-bottom: ${props => theme(props).margin};
  padding: ${props => theme(props).margin};
`;

export const Legend = styled('span')`
  ${props => responsiveFontBlock(props.theme.typography.heading.heading4)};
  margin-bottom: ${props => theme(props).margin};
`;
