/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved
US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import axios from 'axios';
import useAxios from 'axios-hooks';
import { sessionStorage } from '@exo/frontend-common-polaris-session-context';
import { openPopUpFn } from '../helper/utils';

export const useInvokeClickThru = polarisConfig => {
  const { host, clickThruEndpoint } = polarisConfig;

  axios.interceptors.request.use(
    async config => {
      const token = sessionStorage.get()?.token;

      if (token) {
        config.headers = {
          authorization: `Bearer ${token}`
        };
      }
      return config;
    },
    error => Promise.reject(error)
  );

  const [{ loading, error }, executeInvoke] = useAxios(
    {
      url: `${host}${clickThruEndpoint}`,
      method: 'POST',
      withCredentials: true
    },
    {
      manual: true
    }
  );

  const onInvoke = body => {
    executeInvoke({
      url: `${host}${clickThruEndpoint}`,
      data: body
    })
      .then(res => {
        if (res.data) {
          openPopUpFn(res.data.clickThruPage);
        }
      })
      .catch(err => {
        console.error('Something went wrong with service invocation ', err.message);
      });
  };

  return {
    loading,
    error,
    onInvoke
  };
};
