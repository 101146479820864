/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/
import React, { useEffect, useState } from 'react';
import { useIntl } from '@exo/frontend-common-i18n';
import * as S from './GeneralErrorPage.styles';
import { ArrowLeft16 } from '@carbon/icons-react';
import { Button } from '@exo/frontend-components-base';
import { errorSubject } from '@exo/frontend-common-polaris-error';
import { useHistory, Link as ReactLink } from 'react-router-dom';

export const GeneralErrorPage = ({ graphQLError, error, useDisplayButton = true }: Props) => {
  const intlPrefix = 'features.insurance.generalErrorMessage';
  const intl = useIntl(intlPrefix);

  const history = useHistory();

  const onBack = () => {
    errorSubject.next(false);
    history.goBack();
  };
  const [title, setTitle] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (graphQLError) {
      /* c8 ignore next */
      let validationMessage = graphQLError?.graphQLErrors?.[0]?.message;
      let errorMessage = intl.msg(
        'message2',
        'If the error persists, please contact the administrator.'
      ) as string;
      if (validationMessage && validationMessage.toLowerCase().includes('security question')) {
        validationMessage = '';
        errorMessage = intl.msg(
          'message3',
          `You are not allowed. Please set a <hresf>security question</hresf> first.`,
          {
            hresf: str => (
              <ReactLink to={{ pathname: '/account/update-security-info' }}>{str}</ReactLink>
            )
          }
        ) as string;
      } else if (validationMessage && validationMessage.toLowerCase().includes('validation')) {
        errorMessage =
          /* c8 ignore next */
          graphQLError?.graphQLErrors?.[0]?.extensions?.name ||
          (intl.msg('message1', 'Something went wrong. Please try again.') as string);
      } else {
        validationMessage = intl.msg(
          'message1',
          'Something went wrong. Please try again.'
        ) as string;
      }
      setTitle(validationMessage);
      setMessage(errorMessage);
    }
  }, []);

  return (
    <S.PageWrapper className="general-error-page">
      <S.Content>
        <S.Message>{title}</S.Message>
        <S.Message>{message || error}</S.Message>
      </S.Content>
      {useDisplayButton && (
        <S.Footer>
          <Button
            variant="light"
            label={intl.msg('btnLabel', 'Back') as string}
            icon={<ArrowLeft16 />}
            iconPosition="left"
            className="btn-no-padding"
            onClick={onBack}
          />
        </S.Footer>
      )}
    </S.PageWrapper>
  );
};

type Props = {
  graphQLError: any;
  error?: string;
  useDisplayButton?: boolean;
};
