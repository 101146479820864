/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { useQuery } from '@apollo/client';
import { assembleQuery, Fragments, handleApolloError, State } from '@exo/frontend-common-apollo';
import { InsurersByFunctionArgs } from '../model/types';

const transformResponse = <T>(data: any): T => {
  return data?.getInsurersByFunction;
};

export const useInsurersByFunction = <T>(
  { functionId, businessAreaId, skip = false }: InsurersByFunctionArgs,
  fragments: Fragments
): Result<T> => {
  const variables = { functionId, businessAreaId };

  const { called, loading, data, error } = useQuery(
    assembleQuery(
      fragmentNames => `
        query GET_INSURERS_BY_FUNCTION($functionId: ID!, $businessAreaId: ID) {
          getInsurersByFunction( functionId : $functionId, businessAreaId : $businessAreaId) {
            ${fragmentNames}
          }
        }
      `,
      fragments
    ),
    {
      skip,
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only'
    }
  );

  handleApolloError(__filename, error);

  return { called, loading, data: transformResponse(data), error };
};

type Result<T> = {
  data?: T;
  called: boolean;
} & State;
