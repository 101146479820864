/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { renderDefaultError, SmartComponentProps } from '@exo/frontend-common-utils';
import { useEffect } from 'react';
import { useDeleteService } from '../../hooks/useDeleteFavouriteService';
import { useFavouriteServices } from '../../hooks/useFavouriteServices';
import { renderPolarisLoading } from '@exo/frontend-features-insurance-chrome-logic';
import { SuccessType } from '../../model/types';

export const ServicesTabContainer = ({
  render,
  renderError = renderDefaultError,
  event
}: Props) => {
  const { data, loading, error, refetch } = useFavouriteServices<FavouriteService[]>();
  const { deleteService, error: submitError, loading: deleteLoading } = useDeleteService();

  useEffect(() => {
    refetch();
  }, [event?.timestamp]);

  useEffect(() => {
    renderPolarisLoading(loading || deleteLoading);
  }, [loading, deleteLoading]);
  if (error || submitError) return renderError(error || submitError);

  const onDelete = (itemId: string) => {
    deleteService(itemId).then(rsp => {
      if (rsp.data.deleteFavouriteService) refetch();
    });
  };

  return render({
    onDelete: onDelete,
    items: data ?? []
  });
};

type FavouriteService = {
  serviceId: string;
  favouriteServiceName: string;
  favouriteServiceId: string;
  serviceType: string;
  hasAccess: 'Yes' | 'No';
};

type ServicesTabContainerRenderProps = {
  onDelete: (itemId: string) => void;
  items: FavouriteService[];
};

type Props = SmartComponentProps<{
  render: (props: ServicesTabContainerRenderProps) => any;
  event?: SuccessType;
}>;
